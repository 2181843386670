import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { dispatch } from '../index'
import { PURGE } from 'redux-persist'
import { PushNotification, updateBalance } from './user'
import { enqueueSnackbar } from 'notistack'
import { openSnackbar } from './snackbar'
export const fetchPendingSend = createAsyncThunk(
  'fetchPendingSend',
  async token => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + '/send_outreach/get-pending-messages',
      {
        headers: { authorization: token },
      },
    )
    return response.data
  },
)

const initialState = {
  leads: [],
  regenerate: [],
  status: 'idle',
  error: null,
  sentToday: 0,
  sentThisWeek: 0,
  sendingQuota: [],
  generationQueue: [],
  queuedSends: [],
}

const slice = createSlice({
  name: 'send_outreach',
  initialState,
  reducers: {
    popLead(state, action) {
      var update = state.leads
      const index = update.findIndex(
        elem => elem?.recipient_urn === action.payload,
      )
      if (index > -1) {
        update.splice(index, 1)
      }
      state.leads = update
    },
    addRegenerate(state, action) {
      var update = [...state.regenerate]
      // IF ALREADY IN THE REGEN LIST, POP AND RE-INSERT
      // OTHERWISE, JUST INSERT
      const regenIndex = update.findIndex(
        elem => elem.urn === action.payload.urn,
      )
      if (regenIndex > -1) {
        update.splice(regenIndex, 1)
      }
      update.push(action.payload)
      state.regenerate = update
    },
    updateSendingQuota(state, action) {
      state.sendingQuota = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPendingSend.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchPendingSend.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.leads = action.payload.leads
        state.sentToday = action.payload.sentToday
        state.sentThisWeek = action.payload.sentThisWeek
        state.generationQueue = action.payload.generationQueue
        state.queuedSends = action.payload.queuedSends
        // DETECT COMPLETE REGENERATIONS
        var regenUpdate = [...state.regenerate]

        action.payload.leads.forEach(lead => {
          // WAS A REGEN QUEUED?
          let regenIndex = regenUpdate.findIndex(
            item => item.urn === lead.recipient_urn,
          )
          // FOUND A MATCH IN REGEN
          // CHECK FOR MESSAGE UPDATE
          if (regenIndex > -1) {
            // FAN OUT MESSAGES
            const updatedMsgs = {
              outreach: lead.messages.find(
                m =>
                  [
                    'Pending Send',
                    'Failed Outreach Send',
                    'Failed Send',
                    'Queued Send',
                    'Bounced Send',
                  ].includes(m.status).message,
              ),
              f1: lead.messages.find(m => m.status === 'Pending Mild1 Send')
                .message,
              f2: lead.messages.find(m => m.status === 'Pending Mild2 Send')
                .message,
              f3: lead.messages.find(m => m.status === 'Pending Mild3 Send')
                .message,
              subject: lead.subject.message,
            }
            // SKIP SUBJECT
            let regenComplete = ['outreach', 'f1', 'f2', 'f3'].reduce(
              (acc, flag, i) => {
                // IF FLAGGED, COMPARE WITH MESSAGE IN STORE
                if (regenUpdate[regenIndex].regenFlags[i]) {
                  // RETURN TRUE IF MESSAGE IS DIFFERENT
                  return (
                    acc &&
                    updatedMsgs[flag] !== regenUpdate[regenIndex].msgs[flag]
                  )
                } else {
                  // RETURN TRUE IF MESSAGE NOT FLAGGED (SKIP)
                  return acc && true
                }
              },
              true,
            )
            // IF COMPLETE, UPDATE REGEN STATUS IN STORE
            regenUpdate[regenIndex] = {
              ...regenUpdate[regenIndex],
              complete: regenComplete,
            }
            state.regenerate = regenUpdate
          }
        })
      })
      .addCase(fetchPendingSend.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

export default slice.reducer

export const numPendingSend = state => state.leads.length
export const allLeads = state => state.leads
export const getMsgsByURN = (state, urn) =>
  state.leads.find(lead => lead.recipient_urn === urn)

export const GetSendingQuota = async token => {
  axios
    .get(
      process.env.REACT_APP_BACKEND_URL + '/send_outreach/get-sending-quota',
      {
        headers: { authorization: token },
      },
    )
    .then(resp => {
      dispatch(slice.actions.updateSendingQuota(resp.data))
    })
}

export const RegenerateMsg = async (
  token,
  recipientURN,
  messages,
  msgID,
  recipientName,
  userState,
  campaignType,
  regenFlags,
) => {
  var trimmedFlags
  if (campaignType === 'linkedin') {
    trimmedFlags = [...regenFlags].slice(0, 4)
    trimmedFlags.push(false)
  } else {
    trimmedFlags = regenFlags
  }

  dispatch(
    slice.actions.addRegenerate({
      urn: recipientURN,
      regenFlags: trimmedFlags,
      msgs: messages,
      complete: false,
    }),
  )

  const data = new FormData()
  data.append('recipientURN', recipientURN)
  data.append('msgID', msgID)
  data.append('mapleID', userState.maple.id)
  data.append('mapleConfig', JSON.stringify(userState.maple.config))
  data.append('flags', JSON.stringify(trimmedFlags))
  data.append('regenOriginal', JSON.stringify(messages))

  let msg
  if (campaignType === 'email') {
    msg = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/send_outreach/regenerate-email',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(function (error) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with your regeneration request for ${recipientName}`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with your regeneration request for ${recipientName}`,
        //   false,
        //   {
        //     action: 'regenerate-email',
        //     campaignType: campaignType,
        //     recipientURN: recipientURN,
        //     recipientName: recipientName,
        //     id: msgID,
        //     regenFlags: regenFlags,
        //   },
        // )
        return ''
      })
  } else if (campaignType === 'linkedin') {
    msg = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/send_outreach/regenerate-message',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(function (error) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with your regeneration request for ${recipientName}`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with your regeneration request for ${recipientName}`,
        //   false,
        //   {
        //     action: 'regenerate-message',
        //     campaignType: campaignType,
        //     recipientURN: recipientURN,
        //     recipientName: recipientName,
        //     id: msgID,
        //     regenFlags: regenFlags,
        //   },
        // )
        return ''
      })
  }
  if (msg.data.status === 'success') {
    dispatch(fetchPendingSend(token)).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Regeneration in progress for ${recipientName}, check back in a minute or so!`,
          variant: 'alert',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      //   PushNotification(
      //     token,
      //     'success',
      //     `Regeneration in progress for ${recipientName}, check back in a minute or so!`,
      //     false,
      //     {
      //       action: 'regenerate-message',
      //       campaignType: campaignType,
      //       recipientURN: recipientURN,
      //       recipientName: recipientName,
      //       id: msgID,
      //       regenFlags: regenFlags,
      //     },
      //   )
      // })
      dispatch(updateBalance(msg.data.balance))
    })
  }
}

export const SendMessage = async (
  token,
  msgID,
  recipient_name,
  campaignType,
) => {
  const data = new FormData()
  data.append('msgID', msgID)
  let msg
  if (campaignType === 'email') {
    msg = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          '/send_outreach/send-outreach-email',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(function (error) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with submitting send for ${recipient_name}; Try again later`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with submitting send for ${recipient_name}; Try again later`,
        //   false,
        //   {
        //     action: 'send-outreach-email',
        //     campaignType: campaignType,
        //     recipientName: recipient_name,
        //     id: msgID,
        //   },
        // )
        return ''
      })
  } else if (campaignType === 'linkedin') {
    msg = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/send_outreach/send-message-batch',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(function (error) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with submitting send for ${recipient_name}; Try again later`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with submitting send for ${recipient_name}; Try again later`,
        //   false,
        //   {
        //     action: 'send-message-batch',
        //     campaignType: campaignType,
        //     recipientName: recipient_name,
        //     id: msgID,
        //   },
        // )
        return ''
      })
  }

  if (msg.data.status === 'fail') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Something went wrong with submitting send for ${recipient_name}; Try again later`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `Something went wrong with submitting send for ${recipient_name}; Try again later`,
    //   false,
    //   {
    //     action: 'send-message',
    //     campaignType: campaignType,
    //     recipientName: recipient_name,
    //     id: msgID,
    //   },
    // )
    return ''
  } else {
    // enqueueSnackbar(`Message for ${recipient_name} was queued to send!`, {
    //   variant: 'success',
    // })
    dispatch(
      openSnackbar({
        open: true,
        message: `Message for ${recipient_name} was queued to send!`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'success',
    //   `Message for ${recipient_name} was queued to send!`,
    //   false,
    //   {action: "send-message", campaignType: campaignType, recipientName: recipient_name, id: msgID}
    // );
  }
  dispatch(fetchPendingSend(token))
  return 'success'
}

export const CancelQueuedSend = async (
  token,
  msgID,
  recipient_name,
  campaignType,
) => {
  const data = new FormData()
  data.append('msgID', msgID)
  data.append('campaignType', campaignType)
  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/send_outreach/cancel-queued-send',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(function (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with canceling queued send for ${recipient_name}`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with canceling queued send for ${recipient_name}`,
      //   false,
      //   {
      //     action: 'cancel-queued-send',
      //     campaignType: campaignType,
      //     id: msgID,
      //     recipientName: recipient_name,
      //   },
      // )
      return ''
    })
  if (msg.data.status === 'fail') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Something went wrong with canceling queued send for ${recipient_name}`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `Something went wrong with canceling queued send for ${recipient_name}`,
    //   false,
    //   {
    //     action: 'cancel-queued-send',
    //     campaignType: campaignType,
    //     id: msgID,
    //     recipientName: recipient_name,
    //   },
    // )
    return ''
  } else {
    // enqueueSnackbar(`Queued send for ${recipient_name} was cancelled!`, {
    //   variant: 'success',
    // })
    dispatch(
      openSnackbar({
        open: true,
        message: `Queued send for ${recipient_name} was cancelled!`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'success',
    //   `Queued send for ${recipient_name} was cancelled!`,
    //   false,
    //   {action: "cancel-queued-send", campaignType: campaignType, id: msgID, recipientName: recipient_name}
    // );
  }
  dispatch(fetchPendingSend(token))
  return 'success'
}

export const DeleteLead = async (
  token,
  recipientURN,
  recipientName,
  campaignType,
) => {
  const data = new FormData()
  data.append('recipientURN', recipientURN)
  data.append('campaignType', campaignType)
  try {
    const resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + '/send_outreach/delete-lead',
      data,
      {
        headers: { authorization: token },
      },
    )

    dispatch(fetchPendingSend(token)).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `${recipientName} was deleted from your leads list`,
          variant: 'alert',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'success',
      //   `${recipientName} was deleted from your leads list`,
      //   false,
      //   {
      //     action: 'delete-lead',
      //     campaignType: campaignType,
      //     recipientURN: recipientURN,
      //     recipientName: recipientName,
      //   },
      // )
    })
  } catch (error) {
    dispatch(
      openSnackbar({
        open: true,
        message: `Something went wrong with deleting ${recipientName} from your leads list`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `Something went wrong with deleting ${recipientName} from your leads list`,
    //   false,
    //   {
    //     action: 'delete-lead',
    //     campaignType: campaignType,
    //     recipientURN: recipientURN,
    //     recipientName: recipientName,
    //   },
    // )
  }
}

export const UpdateMessage = async (
  token,
  newMsg,
  msgID,
  campaignType,
  recipientName,
) => {
  // enqueueSnackbar('Saving changes ...', {
  //   variant: 'info',
  //   autoHideDuration: 2000,
  // })
  dispatch(
    openSnackbar({
      open: true,
      message: 'Saving changes ...',
      variant: 'alert',
      alert: {
        color: 'info',
      },
      close: false,
    }),
  )
  const data = new FormData()
  data.append('newMessage', newMsg)
  data.append('msgId', msgID)
  data.append('campaignType', campaignType)
  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/send_outreach/update-message',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(function (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with updating the message for ${recipientName}`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with updating the message for ${recipientName}`,
      //   false,
      //   {
      //     action: 'update-message',
      //     campaignType: campaignType,
      //     id: msgID,
      //     recipientName: recipientName,
      //   },
      // )
      return ''
    })
  if (typeof msg.data === 'string') {
    dispatch(fetchPendingSend(token)).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Saved changes to message for ${recipientName}`,
          variant: 'alert',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      // enqueueSnackbar(`Saved changes to message for ${recipientName}`, {
      //   variant: 'success',
      //   autoHideDuration: 2000,
      // })
      // PushNotification(
      //   token,
      //   'success',
      //   `Saved changes to message for ${recipientName}`,
      //   false,
      //   {action: "update-message", campaignType: campaignType, id: msgID, recipientName: recipientName}
      // );
    })
  }
}

export const UpdateSubject = async (token, lead, newSubject, recipientName) => {
  dispatch(
    openSnackbar({
      open: true,
      message: 'Saving changes ...',
      variant: 'alert',
      alert: {
        color: 'info',
      },
      close: false,
    }),
  )
  // enqueueSnackbar('Saving changes ...', {
  //   variant: 'info',
  //   autoHideDuration: 2000,
  // })
  const data = new FormData()
  data.append('lead', lead)
  data.append('newSubject', newSubject)
  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/send_outreach/update-subject',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(function (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with updating the subject for ${recipientName}`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with updating the subject for ${recipientName}`,
      //   false,
      //   { action: 'update-subject', lead: lead, recipientName: recipientName },
      // )
      return ''
    })
  if (typeof msg.data === 'string') {
    dispatch(fetchPendingSend(token)).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Saved changes to subject for ${recipientName}`,
          variant: 'alert',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      // enqueueSnackbar(`Saved changes to subject for ${recipientName}`, {
      //   variant: 'success',
      //   autoHideDuration: 2000,
      // })
      // PushNotification(
      //   token,
      //   'success',
      //   `Saved changes to subject for ${recipientName}`,
      //   false,
      //   {action: "update-subject", lead: lead, recipientName: recipientName}
      // );
    })
  }
}
