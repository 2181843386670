import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { dispatch } from '../index'
import { enqueueSnackbar } from 'notistack'
import { openSnackbar } from './snackbar'
// ----------------------------------------------------------------------

const initialState = {
  zone: '',
  companyName: '',
  lastConvoUpdate: '',
  sendLimitOn: true,
  connection: 'CONNECTED',
  allowReconnection: false,
  teamCode: '',
  teamMembers: [],
  balance: 0,
  maple: {
    id: '',
    config: {},
  },
  dateJoined: '',
  notifications: [],
  status: 'idle',
  error: null,
}

export const fetchUserInfo = createAsyncThunk('fetchUserInfo', async token => {
  const resp = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/user/get-user-info',
    {
      headers: { authorization: token },
    },
  )
  return resp.data
})

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAllowReconnection(state, action) {
      state.allowReconnection = action.payload
    },
    updateBalance(state, action) {
      state.balance = action.payload
    },
    updateNotifications(state, action) {
      state.notifications = action.payload
    },
    updateMapleConfig(state, action) {
      state.maple = Object.assign({}, state.maple, {
        ...state.maple,
        config: action.payload,
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserInfo.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        if (action.payload.status === 'ERR_NOT_IN_USERS') {
          state.status = action.payload.status
        } else {
          state.status = 'succeeded'
          state.zone = action.payload.zone
          state.companyName = action.payload.companyName
          state.lastConvoUpdate = action.payload.lastConvoUpdate
          state.sendLimitOn = action.payload.sendLimitOn
          state.connection = action.payload.connection
          state.allowReconnection = action.payload.allowReconnection
          state.teamCode = action.payload.teamCode
          state.teamMembers = action.payload.teamMembers
          state.dateJoined = action.payload.dateJoined
          state.maple = {
            id: action.payload.mapleID,
            config: action.payload.mapleConfig,
          }
          state.balance = action.payload.balance
          state.notifications = action.payload.notifications
        }
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

// Reducer
export default slice.reducer
export const { updateAllowReconnection, updateBalance } = slice.actions

export const toggleAllowReconnection = async (token, toggle) => {
  const data = new FormData()
  data.append('allowReconnection', toggle)
  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/user/toggle-allow-reconnection',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateAllowReconnection(msg.data.allowReconnection))
  }
}

export const GetNotifications = async token => {
  const msg = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/user/get-notifications',
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateNotifications(msg.data.notifications))
    return true
  }
  return false
}

export const PushNotification = async (
  token,
  variant,
  message,
  isFatal = null,
  ref,
) => {
  enqueueSnackbar(message, { variant: variant })

  const data = new FormData()
  data.append('variant', variant)
  data.append('message', message)
  data.append('isFatal', isFatal)
  data.append('ref', JSON.stringify(ref))

  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/user/push-notification',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateNotifications(msg.data.notifications))
    return true
  }
  return false
}

export const HideNotification = async (token, notiID) => {
  const data = new FormData()
  data.append('notiID', notiID)

  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/user/hide-notification',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateNotifications(msg.data.notifications))
    return true
  }
  return false
}

export const HideAllNotifications = async token => {
  const msg = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/user/hide-all-notifications',
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateNotifications(msg.data.notifications))
    return true
  }
  return false
}

export const ReadNotifications = async token => {
  const msg = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/user/read-notifications',
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateNotifications(msg.data.notifications))
    return true
  }
  return false
}

// TEAM MANAGEMENT

export const JoinTeamViaCode = async (token, code) => {
  const data = new FormData()
  data.append('code', code)
  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/user/join-team-via-code',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status === 'success') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Successfully joined team`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(token, 'success', `Successfully joined team`, false, {
    //   action: 'join-team-via-code',
    //   code: code,
    // })
    return resp.data
  } else {
    dispatch(
      openSnackbar({
        open: true,
        message: `Invalid invitation code`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(token, 'error', `Invalid invitation code`, false, {
    //   action: 'join-team-via-code',
    // })
    return resp.data
  }
}

// ============================= MAPLE STORY ==============================

export const CreateMapleStartFreeTrial = async token => {
  const resp = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/maple/create-maple-user-free-trial',
    {
      headers: { authorization: token },
    },
  )
  return resp
}

export const UpdateMapleConfig = async (token, state, update) => {
  const data = new FormData()
  data.append('mapleID', state.id)
  data.append('mapleConfig', JSON.stringify(update))
  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/maple/update-maple-config',
    data,
    {
      headers: { authorization: token },
    },
  )
  dispatch(slice.actions.updateMapleConfig(update))
  return resp
}

export const GetBalance = async (token, state) => {
  const data = new FormData()
  data.append('mapleID', state.maple.id)
  axios
    .post(process.env.REACT_APP_BACKEND_URL + '/maple/get-balance', data, {
      headers: { authorization: token },
    })
    .then(resp => {
      dispatch(slice.actions.updateBalance(resp.data.balance))
      return resp.data.balance
    })
}

export const GetCheckoutSession = async (
  token,
  state,
  pricePlan,
  autoRenew,
) => {
  const data = new FormData()
  data.append('mapleID', state.maple.id)
  data.append('mapleConfig', state.maple.config)
  data.append('pricePlan', pricePlan)
  data.append('autoRenew', autoRenew)
  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/maple/checkout-load-balance',
    data,
    {
      headers: { authorization: token },
    },
  )
  dispatch(slice.actions.updateMapleConfig(resp.data.mapleConfig))
  return resp
}

export const ExportLeads = async (token, state, units) => {
  const data = new FormData()
  data.append('units', units)
  data.append('mapleID', state.maple.id)
  data.append('mapleConfig', state.maple.config)

  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/maple/charge-units',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateBalance(msg.data.balance))
    return true
  }
  return false
}

// MISC USER ACTIONS

export const SubmitTicket = async (email, name, token, description) => {
  const data = new FormData()
  data.append('email', email)
  data.append('name', name)
  data.append('description', description)

  const msg = await axios
    .post(process.env.REACT_APP_BACKEND_URL + '/user/submit-ticket', data, {
      headers: { authorization: token },
    })
    .catch(function (error) {
      if (typeof error.response !== 'undefined') {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with submitting your ticket`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with submitting your ticket`,
        //   false,
        //   {
        //     action: 'submit-ticket',
        //     email: email,
        //     name: name,
        //     description: description,
        //   },
        // )
        return false
      }
      return false
    })
  if (msg.data === 'Submitted Ticket') {
    return true
  }
  return false
}

export const AlertError = async (error_msg, error_stack, isLoggedIn, fb_id) => {
  const data = new FormData()
  data.append('error_msg', error_msg)
  data.append('error_stack', error_stack)
  if (isLoggedIn) {
    data.append('fb_id', fb_id)
  }
  const resp = axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/preauth/send-error-alert-email',
      data,
      {},
    )
    .catch(function (error) {
      if (typeof error.response !== 'undefined') {
        dispatch(openSnackbar({}))
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with submitting your ticket`,
        //   false,
        //   {
        //     action: 'submit-ticket',
        //     email: email,
        //     name: name,
        //     description: description,
        //   },
        // )
        return false
      }
      return false
    })
  if (resp.data === 'Alert') {
    return true
  }
  return false
}
