// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconReportAnalytics, IconRocket, IconChartBar} from "@tabler/icons";

// ==============================|| setup MENU ITEMS ||============================== //

const Dashboard = {
  id: "Analytics",
  title: <FormattedMessage id="Analyze Data" />,
  icon: IconChartBar,
  type: "item",
  url: "/analytics",
};

export default Dashboard;
