import Switch from '@mui/material/Switch';
import {useTheme} from '@mui/material/styles';

const IconSwitch = ({icon, checkedIcon, checked, onChange}) => {
    const theme = useTheme();
    return (
        <Switch 
            checked={checked}
            onChange={onChange}
            sx={{
                width: 70,
                height: 40,
                '& .MuiSwitch-switchBase': {
                    mt: 0.4,
                    color: 'white',
                    backgroundColor: "#fdd5df",
                    '&.Mui-checked': {
                        color: '#fff',
                        backgroundColor: theme.palette.josh.magenta,
                        transform: 'translateX(35px)',
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            backgroundColor: "#ffb3c6"
                            },
                        },
                    '&:hover': {
                        color: 'white',
                        backgroundColor: "#fdd5df",
                        '&.Mui-checked': {
                            color: '#fff',
                            backgroundColor: theme.palette.josh.magenta,
                            // transform: 'translateX(22px)',
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: "#ffc2d1"
                            },
                            },
                    }
                },
                '& .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: "lightgrey"
                    },
            }}
            icon={icon} 
            checkedIcon={checkedIcon} 
        />
)};

export default IconSwitch;