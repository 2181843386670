// third-party
import { FormattedMessage } from 'react-intl'

// assets
import { IconBrandTelegram } from '@tabler/icons'
// import { numPendingSend } from "store/slices/sendOutreach";
// ==============================|| setup MENU ITEMS ||============================== //

// const num = numPendingSend()
const SendOutreach = {
  id: 'SendOutreach',
  title: <FormattedMessage id='Send Outreach' />,
  icon: IconBrandTelegram,
  type: 'item',
  url: '/send-outreach',
  badge: true,
}

export default SendOutreach
