import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Badge,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project imports
import useConfig from 'hooks/useConfig'
import { useDispatch, useSelector } from 'store'
import { activeID, activeItem, openDrawer } from 'store/slices/menu'

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const TopLevelNavItem = ({ item, level, parentId }) => {
  const numPendingSend = useSelector(state => state.send_outreach.leads?.length)
  const numAction = useSelector(
    state => state.conversations.actionItems?.length,
  )
  const dispatch = useDispatch()

  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { pathname } = useLocation()
  const { layout, borderRadius } = useConfig()

  const { selectedItem, drawerOpen } = useSelector(state => state.menu)
  const isSelected = selectedItem.findIndex(id => id === item.id) > -1

  const Icon = item?.icon
  const itemIcon = item?.icon ? (
    <Icon
      stroke={1.5}
      size='24px'
      style={{
        color: isSelected
          ? theme.palette.secondary.main
          : theme.palette.text.primary,
      }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        color: isSelected
          ? theme.palette.secondary.main
          : theme.palette.text.primary,
        width: selectedItem.findIndex(id => id === item?.id) > -1 ? 8 : 6,
        height: selectedItem.findIndex(id => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const itemHandler = id => {
    dispatch(activeItem([id]))
    if (matchesSM) dispatch(openDrawer(false))
    dispatch(activeID(parentId))
  }

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex(id => id === item.id)
    if (currentIndex > -1) {
      dispatch(activeItem([item.id]))
    }
    // eslint-disable-next-line
  }, [pathname])

  return (
    <>
      {matchDownMd ? (
        <List>
          <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
              borderRadius: `${borderRadius}px`,
              p: 1,
              my: 0.5,
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'inherit',
            }}
            selected={isSelected}
            onClick={() => itemHandler(item.id)}
          >
            {item.badge ? (
              <Badge
                badgeContent={
                  item.id === 'SendOutreach' ? numPendingSend : numAction
                }
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                sx={{
                  borderRadius: '50%',
                  '& .MuiBadge-badge': {
                    // fontSize: '10px',
                    color: theme.palette.secondary.main,
                    // bottom: "-1qpx",
                    // opacity: "80%",
                    right: '5px',
                    // backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    my: 'auto',
                    minWidth: 28,
                    mx: 0.5,
                  }}
                >
                  {itemIcon}
                </ListItemIcon>
              </Badge>
            ) : (
              <ListItemIcon
                sx={{
                  my: 'auto',
                  minWidth: 28,
                  mx: 0.5,
                }}
              >
                {itemIcon}
              </ListItemIcon>
            )}

            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? 'h5' : 'body1'}
                  color='inherit'
                >
                  {isSelected ? item.title : ''}
                </Typography>
              }
              secondary={
                item.caption && (
                  <Typography
                    variant='caption'
                    sx={{ ...theme.typography.subMenuCaption }}
                    display='block'
                    gutterBottom
                  >
                    {item.caption}
                  </Typography>
                )
              }
            />
          </ListItemButton>
        </List>
      ) : (
        <List>
          <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
              borderRadius: `${borderRadius}px`,
              p: 1,
              my: 0.5,
              mr: 0.5,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'inherit',
            }}
            selected={isSelected}
            onClick={() => itemHandler(item.id)}
          >
            {item.badge ? (
              <Badge
                sx={{
                  borderRadius: '50%',
                  '& .MuiBadge-badge': {
                    color: theme.palette.secondary.main,
                    right: '6px',
                  },
                }}
                badgeContent={
                  item.id === 'SendOutreach' ? numPendingSend : numAction
                }
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ListItemIcon
                  sx={{
                    my: 'auto',
                    mx: 0.5,
                    minWidth: 28,
                  }}
                >
                  {itemIcon}
                </ListItemIcon>
              </Badge>
            ) : (
              <ListItemIcon sx={{ my: 'auto', mx: 0.5, minWidth: 28 }}>
                {itemIcon}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? 'h5' : 'body1'}
                  color='inherit'
                >
                  {item.title}
                </Typography>
              }
              secondary={
                item.caption && (
                  <Typography
                    variant='caption'
                    sx={{ ...theme.typography.subMenuCaption }}
                    display='block'
                    gutterBottom
                  >
                    {item.caption}
                  </Typography>
                )
              }
            />
          </ListItemButton>
        </List>
      )}
    </>
  )
}

TopLevelNavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  parentId: PropTypes.string,
}

export default TopLevelNavItem
