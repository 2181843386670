import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useSelector } from 'store'
import useAuth from 'hooks/useAuth'
import { GetCheckoutSession, UpdateMapleConfig } from 'store/slices/user'
import IconSwitch from './IconSwitch'
import { FiRefreshCw } from 'react-icons/fi'

const priceTable = [
  {
    price: '99',
    base: '165',
    bonus: '0',
    credits: '165',
    amount: 165,
  },
  {
    // price: 285,
    price: '229',
    base: '380',
    bonus: '20',
    credits: '400',
    amount: 400,
  },
  {
    price: '529',
    base: '880',
    bonus: '120',
    credits: '1,000',
    amount: 1000,
  },
  {
    price: '1,499',
    base: '2,500',
    bonus: '500',
    credits: '3,000',
    amount: 3000,
  },
]
// =============================|| PRICING - PRICE 2 ||============================= //

const LoadBalanceDialog = () => {
  const theme = useTheme()
  const auth = useAuth()
  const userState = useSelector(state => state.user)
  const [selectedPricing, setSelectedPricing] = useState(
    priceTable.find(
      item =>
        item.amount ===
        parseInt(
          userState.maple.config
            ? userState.maple.config.recent_pricing_plan
            : '165',
        ),
    ),
  )
  const [autoRenewToggle, setAutoRenewToggle] = useState(
    userState.maple.config && userState.maple.config.auto_renew,
  )

  return (
    <>
      <DialogTitle>Load Balance</DialogTitle>
      <DialogContent
        sx={{
          px: 4,
          borderTop: '1px solid',
          borderColor: theme.palette.grey[200],
        }}
      >
        <Stack spacing={1.5}>
          <Box
            sx={{
              border: '1.5px solid',
              borderColor: theme.palette.primary.main,
              bgcolor: theme.palette.primary.light,
              borderRadius: '8px',
              p: 1,
              m: -2,
              mt: 2,
              mb: 1,
            }}
          >
            <Typography align='center'>
              Your current balance is{' '}
              <Typography color='primary' display='inline'>
                <strong>{userState.balance} credits</strong>
              </Typography>
            </Typography>
          </Box>
          <Table sx={{ 'td, th': { py: 1 } }}>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  <Typography variant='overline' lineHeight='16px'>
                    Price
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='overline' lineHeight='16px'>
                    Rocketbrew Credits
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceTable.map(row => {
                return (
                  <TableRow
                    onClick={() => {
                      setSelectedPricing(row)
                    }}
                    key={row.credits}
                    id={row.credits}
                    sx={{
                      ':hover': {
                        cursor: 'pointer',
                        backgroundColor: theme.palette.primary.light,
                      },
                      '&.Mui-selected, &.Mui-selected:hover': {
                        borderColor: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.main,
                        '& > .MuiTableCell-root, .MuiTypography-root': {
                          color: 'white',
                        },
                      },
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    selected={selectedPricing?.credits === row.credits}
                  >
                    <TableCell align='center'>
                      <Typography variant='h4'>${row.price}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Stack>
                        <Typography>
                          <strong>{row.base} credits</strong>
                          {row.bonus !== '0' && (
                            <Typography color='primary' display='inline'>
                              <strong> + {row.bonus} bonus credits</strong>
                            </Typography>
                          )}
                        </Typography>
                        <Typography
                          variant='overline'
                          lineHeight='16px'
                          color={theme.palette.primary[600]}
                        >
                          ≈ {row.credits} outreach sequences
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Divider />
          {selectedPricing && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pb={2}
            >
              <Typography>Your balance after checkout</Typography>
              <Typography color={theme.palette.josh.magenta}>
                <strong>
                  {userState.balance + selectedPricing.amount} credits
                </strong>
              </Typography>
            </Stack>
          )}
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
          >
            <Typography>
              <Typography display='inline' color={theme.palette.josh.magenta}>
                <strong>Auto-Renew</strong>
              </Typography>{' '}
              using same plan in the future
            </Typography>
            <IconSwitch
              checked={autoRenewToggle}
              onChange={e => {
                setAutoRenewToggle(e.target.checked)
                auth.firebaseGetToken().then(token => {
                  UpdateMapleConfig(token, userState.maple, {
                    ...userState.maple.config,
                    auto_renew: e.target.checked,
                  })
                })
              }}
              icon={<FiRefreshCw />}
              checkedIcon={<FiRefreshCw />}
            />
          </Stack>
          <Stack>
            <Button
              variant='contained'
              onClick={() => {
                auth.firebaseGetToken().then(async token => {
                  GetCheckoutSession(
                    token,
                    userState,
                    selectedPricing.credits,
                    autoRenewToggle,
                  ).then(resp => {
                    if (resp.data.status === 'success') {
                      window.open(resp.data.url, '_self', 'noopener,noreferrer')
                    } else {
                      alert('Could not retrieve a payment link. Try again.')
                    }
                  })
                })
              }}
            >
              Proceed to Checkout
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  )
}

export default LoadBalanceDialog
