import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { dispatch } from '../index'
import { PushNotification } from './user'
import { openSnackbar } from './snackbar'
// ----------------------------------------------------------------------

const apolloParamsTemplate = {
  // JOB TITLE
  person_titles: [],
  person_not_titles: [],
  person_seniorities: [],
  person_department_or_subdepartments: [],
  // person_past_titles: [],
  // COMPANY
  organization_ids: [],
  not_organization_ids: [],
  q_organization_domains: '',
  q_not_organization_domains: '',
  // q_company_include: "",
  // q_company_exclude: "",
  // q_not_organization_search_list_id: [],
  // q_organization_search_list_id: [],
  // LOCATION
  person_locations: [],
  person_not_locations: [],
  // NUM EMPLOYEES
  // organization_num_employees_ranges_custom: ["", ""], // NOT ACTUAL PARAM
  organization_num_employees_ranges: [],
  // CUSTOM ["13,59"]
  // INDUSTRY
  organization_industry_tag_ids: [],
  organization_not_industry_tag_ids: [],
  // KEYWORD
  included_organization_keyword_fields: [],
  included_anded_organization_keyword_fields: [],
  excluded_organization_keyword_fields: [],
  q_organization_keyword_tags: [],
  q_anded_organization_keyword_tags: [],
  q_not_organization_keyword_tags: [],
  // TECHNOLOGY
  currently_using_any_of_technology_uids: [],
  currently_using_all_of_technology_uids: [],
  // REVENUE
  revenue_range: {},
  organization_trading_status: ['private', 'public'],
  // OTHER
  // contact_email_status_v2: [],
  // organization_department_or_subdepartment_counts: {},
  // organization_latest_funding_stage_cd: [],
  // search_signal_ids: [],
  // person_total_yoe_range: {},
  // person_days_in_current_title_range: {},
  // supported_any_languages: [],
  // at_least_num_languages: 0,
  // event_categories:[],
}

const initialState = {
  campaigns: [],
  teamCampaigns: [],
  selectedCampaign: {},
  searchParams: apolloParamsTemplate,
  checkedLeads: [],
  pagedResults: [],
  searchResults: [],
  searchCount: 0,
  savedSearch: [],
  autoValueProp: [],
  status: 'idle',
  error: null,
}

export const getCampaigns = createAsyncThunk('getCampaigns', async token => {
  const resp = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/campaigns/get-campaigns',
    {
      headers: { authorization: token },
    },
  )
  return resp.data
})

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    updateCampaignsState(state, action) {
      var camps = []
      action.payload.forEach(item => {
        var c = {
          ...item,
          anatomy:
            item.anatomy === 0
              ? {
                  tone: 'friendly',
                  opener: {
                    strategy: 'rb_generated',
                    customText: '',
                  },
                  bulk: {
                    strategy: 'rb_generated',
                    customText: '',
                    use_cases: [],
                  },
                  cta: {
                    strategy: 'rb_generated',
                    customText: '',
                  },
                }
              : JSON.parse(item.anatomy),
        }
        camps.push(c)
      })
      state.campaigns = camps
    },
    updateSearchResults(state, action) {
      if (action.payload.length > 0) {
        state.searchResults = action.payload
        var update = [...state.pagedResults]
        update.push(action.payload)
        state.pagedResults = update
      }
    },
    updateSearchCount(state, action) {
      state.searchCount = action.payload
    },
    restorePagedResult(state, action) {
      state.searchResults = state.pagedResults[action.payload]
    },
    clearSearchResults(state) {
      state.searchResults = []
      state.searchCount = 0
      state.pagedResults = []
    },
    clearPagedResults(state) {
      state.pagedResults = []
    },
    clearCheckedLeads(state) {
      state.checkedLeads = []
    },
    resetSearchParams(state) {
      state.searchParams = apolloParamsTemplate
    },
    deselectCampaign(state) {
      state.selectedCampaign = {}
    },
    updateSavedSearch(state, action) {
      state.savedSearch = action.payload
    },
    updateAutoValueprop(state, action) {
      state.autoValueProp = action.payload
    },
    updateCheckedLeads(state, action) {
      state.checkedLeads = action.payload
    },
    updateSearchParams(state, action) {
      state.searchParams = action.payload
    },
    updateSelectedCampaign(state, action) {
      state.selectedCampaign = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCampaigns.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.status = 'succeeded'
        var camps = []
        action.payload.campaigns.forEach(item => {
          var c = {
            ...item,
            anatomy:
              item.anatomy === 0
                ? {
                    tone: 'friendly',
                    opener: {
                      strategy: 'rb_generated',
                      customText: '',
                    },
                    bulk: {
                      strategy: 'rb_generated',
                      customText: '',
                      use_cases: [],
                    },
                    cta: {
                      strategy: 'rb_generated',
                      customText: '',
                    },
                  }
                : JSON.parse(item.anatomy),
          }
          camps.push(c)
        })
        state.campaigns = camps
        state.inboxList = action.payload.inboxList
        state.savedSearch = action.payload.savedSearch
        state.autoValueProp = action.payload.autoValueProp
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

// Reducer
export default slice.reducer
export const {
  restorePagedResult,
  clearSearchResults,
  clearPagedResults,
  updateCheckedLeads,
  updateSearchParams,
  updateSelectedCampaign,
} = slice.actions

export const CheckLeads = async (
  token,
  leadslist,
  campaignType,
  importMethod,
  userState,
  excludeSettings,
  teamMembers,
) => {
  const data = new FormData()
  data.append('campaignType', campaignType)
  data.append('importMethod', importMethod)
  data.append('excludeSettings', JSON.stringify(excludeSettings))
  data.append('teamMembers', JSON.stringify(teamMembers))

  // MAPLE USER ?
  if (userState.maple.id) {
    data.append('balance', Math.floor(userState.balance))
    data.append('mapleID', userState.maple.id)
  }

  var parsedLeads = []

  switch (importMethod) {
    case 'plain-text':
      // REFORMAT URL TO DICTS
      parsedLeads = leadslist.filter(item => item.length > 0)
      parsedLeads = parsedLeads.map(url => {
        return { linkedinURL: url }
      })
      break
    case 'crm-import':
    case 'find-leads':
    case 'csv-import':
    default:
      parsedLeads = leadslist
      break
  }

  data.append('leadslist', JSON.stringify(parsedLeads))

  const msg = await axios
    .post(process.env.REACT_APP_BACKEND_URL + '/campaigns/check-leads', data, {
      headers: { authorization: token },
    })
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with checking your submitted leads`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with checking your submitted leads`,
      //   false,
      //   {
      //     action: 'check-leads',
      //     leads: JSON.stringify(parsedLeads),
      //     campaignType: campaignType,
      //     importMethod: importMethod,
      //     excludeSettings: JSON.stringify(excludeSettings),
      //   },
      // )
      return ''
    })
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateCheckedLeads(msg.data.checkedLeads))
    return msg.data.checkedLeads
  }
}

export const SubmitScraping = async (
  token,
  campaign,
  leads,
  userState,
  autoSend,
  searchParams,
) => {
  const data = new FormData()
  data.append('campaign', campaign)
  data.append('leads', JSON.stringify(leads))
  data.append('mapleID', userState.maple.id)
  data.append('mapleConfig', JSON.stringify(userState.maple.config))
  data.append('autoSend', autoSend)
  data.append('searchParams', JSON.stringify(searchParams))

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/campaigns/submit-scraping',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(function (error) {
      if (typeof error.response !== 'undefined') {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with your submission request`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with your submission request`,
        //   false,
        //   {
        //     action: 'subimt-scraping',
        //     campaign: campaign,
        //     leads: JSON.stringify(leads),
        //     autoSend: autoSend,
        //     resp_data: JSON.stringify(error.response.data),
        //   },
        // )
        return false
      }
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with your submission request`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with your submission request`,
      //   false,
      //   {
      //     action: 'subimt-scraping',
      //     campaign: campaign,
      //     leads: JSON.stringify(leads),
      //     autoSend: autoSend,
      //   },
      // )
      return false
    })
  if (msg.data.status === 'success') {
    // CLEAR CHECKED LEADS + SEARCH RESULTS TO AVOID RESUBMISSION
    dispatch(slice.actions.clearSearchResults())
    dispatch(slice.actions.clearCheckedLeads())
    return msg.data
  }
}

// USED TO CREATE NEW CAMPAIGN
export const SaveCampaign = async (
  token,
  campaign,
  matchedHeader,
  fieldMapping,
  parsedFile,
) => {
  const data = new FormData()
  data.append('title', campaign.title)
  data.append('modelName', campaign.modelName)
  data.append('modelOption', campaign.modelOption)
  data.append('valueProp', campaign.valueProp)
  data.append('campaignType', campaign.campaignType)
  data.append('schedule', campaign.schedule)
  if (campaign.modelName === 'edna') {
    data.append('anatomy', JSON.stringify(campaign.anatomy))
  } else if (campaign.modelName === 'florence') {
    data.append('matchedHeader', JSON.stringify(matchedHeader))
    data.append('fieldMapping', JSON.stringify(fieldMapping))
    data.append('parsedFile', JSON.stringify(parsedFile))
  }
  if (campaign.subject) {
    data.append('subject', campaign.subject)
  }

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/campaigns/save-campaign',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with creating your new campaign`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with creating your new campaign`,
      //   false,
      //   { action: 'save-campaign', campaign: campaign },
      // )
      return ''
    })
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateCampaignsState(msg.data.campaigns))
    return { ...campaign, id: msg.data.campaignID }
  }
}

// USERS CAN TOGGLE TO SHOW OR HIDE CAMPAIGNS
export const EditCampaignSettings = async (
  token,
  campaignID,
  property,
  param,
) => {
  const data = new FormData()
  data.append('campaignID', campaignID)
  data.append('property', property)
  if (property === 'title') {
    data.append('title', param)
  } else if (property === 'showToggle') {
    data.append('toggle', param)
  } else if (property === 'delete') {
    data.append('campaignType', param)
  }

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/campaigns/edit-campaign-settings',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with updating your campaign`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with updating your campaign`,
      //   false,
      //   {
      //     action: 'edit-campaign-settings',
      //     campaignID: campaignID,
      //     property: property,
      //     param: param,
      //   },
      // )
      return ''
    })
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateCampaignsState(msg.data.campaigns))
  }
}

// APOLLO

export const FuzzySearch = async (token, query, mode) => {
  // POST WITH DATA
  const data = new FormData()
  data.append('query', query)
  data.append('mode', mode)
  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/apollo/fuzzy-search',
    data,
    {
      headers: { authorization: token },
    },
  )
  return resp.data
}

export const FindLeads = async (
  token,
  searchParams,
  campaignType,
  pageNumber,
) => {
  const data = new FormData()
  data.append('searchParams', JSON.stringify(searchParams))
  data.append('campaignType', campaignType)
  data.append('pageNumber', pageNumber)

  const msg = await axios
    .post(process.env.REACT_APP_BACKEND_URL + '/apollo/find-leads', data, {
      headers: { authorization: token },
    })
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with finding leads`,
          variant: 'error',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with finding leads`,
      //   false,
      //   {
      //     action: 'find-leads',
      //     searchParams: JSON.stringify(searchParams),
      //     campaignType: campaignType,
      //     pageNumber: pageNumber,
      //   },
      // )
      return ''
    })
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateSearchCount(msg.data.searchCount))
    dispatch(slice.actions.updateSearchResults(msg.data.searchResults))
  }
}

export const UpdateSavedSearch = async (token, mode, inputData, title) => {
  const data = new FormData()
  data.append('mode', mode)

  switch (mode) {
    case 'new':
      data.append('title', title)
      data.append('searchParams', JSON.stringify(inputData))
      break
    case 'edit-title':
      data.append('title', title)
      data.append('id', inputData)
      break
    case 'delete':
      // data.append("title", title)
      data.append('id', inputData)
      break
    default:
      return
  }

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/apollo/update-saved-search',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with saving your search parameters`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with saving your search parameters`,
      //   false,
      //   {
      //     action: 'update-saved-search',
      //     mode: mode,
      //     inputData: JSON.stringify(inputData),
      //     title: title,
      //   },
      // )
      return ''
    })
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateSavedSearch(msg.data.savedSearch))
    dispatch(
      openSnackbar({
        open: true,
        message: `Successfully updated saved search`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'success',
    //   `Successfully updated saved search`,
    //   false,
    //   {
    //     action: 'update-saved-search',
    //     mode: mode,
    //     inputData: JSON.stringify(inputData),
    //     title: title,
    //   },
    // )
  }
}

export const PullLeadsFromCRM = async (
  token,
  crmMapping,
  selectedCRM,
  campaignType,
  crmLimit,
) => {
  const data = new FormData()
  data.append('crmMapping', JSON.stringify(crmMapping))
  data.append('campaignType', campaignType)
  data.append('limit', crmLimit)

  var msg = undefined
  if (selectedCRM === 'Hubspot') {
    msg = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/hubspot/contacts-for-scraping',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(() => {})
  } else if (selectedCRM === 'Pipedrive') {
    msg = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/pipedrive/persons-for-scraping',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(() => {})
  } else {
    console.error('ERR_UNKNOWN_CRM')
  }
  if (msg.data.status === 'success') {
    return msg.data.leads
  }
}
