import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import dayjs from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
dayjs.extend(isBetweenPlugin)

export const fetchAnalyticsData = createAsyncThunk(
  'loadAnalyticsData',
  async token => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + '/analytics/get-analytics',
      {
        headers: { authorization: token },
      },
    )
    return {
      memberData: resp.data.memberData,
      totalData: resp.data.totalData,
    }
  },
)

const initialState = {
  memberData: [],
  totalData: [],
  status: 'idle',
  error: null,
}

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAnalyticsData.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchAnalyticsData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.memberData = action.payload.memberData
        state.totalData = action.payload.totalData
      })
      .addCase(fetchAnalyticsData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

export default slice.reducer
export const {} = slice.actions
