// third-party
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { dispatch } from '../index'
import { PURGE } from 'redux-persist'
import { enqueueSnackbar } from 'notistack'
import { PushNotification } from './user'
import { openSnackbar } from './snackbar'
// MERGING OLD AND NEW CONVO, CLEAN UP LATER
export const fetchTracking = createAsyncThunk('fetchTracking', async token => {
  const req1 = axios.get(
    process.env.REACT_APP_BACKEND_URL + '/conversations/fetch-tracking',
    {
      headers: { authorization: token },
    },
  )
  const req2 = axios.get(
    process.env.REACT_APP_BACKEND_URL + '/conversations/get-conversations',
    {
      headers: { authorization: token },
    },
  )

  const [resp1, resp2] = await Promise.all([req1, req2])

  return {
    resp1: resp1.data,
    resp2: resp2.data,
  }
})

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  viewLeads: [],
  counts: {
    conversing: 0,
    awaitingResponse: 0,
    pendingConnection: 0,
    responseReady: 0,
    requiresAction: 0,
    all: 0,
  },
  birdseye: {
    linkedin: {
      requireAction: 0,
      awaitingResponse: 0,
      onAutomated: 0,
      closed: 0,
    },
    email: { requireAction: 0, awaitingResponse: 0, onAutomated: 0, closed: 0 },
  },
  leads: [],
  conversations: {},
  addressbook: [],
  scheduledLog: [],
  sentLog: [],
  responses: [],
  actionItems: [],
  threads_data: [],
  activityLogs: {},
  status: 'idle',
}

const slice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload
    },
    // GET MAILS
    getMailsSuccess(state, action) {
      state.mails = action.payload.mails
      state.unreadCount = action.payload.unreadCount
    },
    // FILTER MAILS
    filterMailsSuccess(state, action) {
      state.mails = action.payload
    },
    // FILTER MAILS
    updateFilter(state, action) {
      state.viewLeads = action.payload
    },
    updateConvo(state, action) {
      state.conversations = Object.assign(state.conversations, action.payload)
    },
    updateActivityLog(state, action) {
      state.activityLogs = {
        ...state.activityLogs,
        [action.payload.recipient_id]: {
          campaign_type: action.payload.campaign_type,
          activities: JSON.parse(action.payload.activity_log),
        },
      }
    },
    updateResp(state, action) {
      state.responses = action.payload
    },
    updateActionItems(state, action) {
      // ACTION ITEM LIST
      var update = [...state.actionItems]
      const index = update.findIndex(
        elem => elem.recipient_urn === action.payload.recipient,
      )
      if (index > -1) {
        update.splice(index, 1)
        state.actionItems = update
      }
      // EDIT LEADS DATA
      var leadUpdate = [...state.leads]
      const leadIndex = state.leads.findIndex(
        elem => elem.recipient_urn === action.payload.recipient,
      )
      if (leadIndex > -1) {
        var leadBefore = leadUpdate.splice(leadIndex, 1)[0]
        var leadAfter = {
          ...leadBefore,
          conversation_status: action.payload.newStatus,
        }
        leadUpdate.push(leadAfter)
        state.leads = leadUpdate
        const campType = leadAfter.campaignType
        // UPDATE BIRDSEYE COUNT
        var birdseyeUpdate = Object.assign({}, state.birdseye)
        if (action.payload.newStatus.startsWith('Closed')) {
          birdseyeUpdate = {
            ...state.birdseye,
            [campType]: {
              ...state.birdseye[campType],
              requireAction: state.birdseye[campType].requireAction - 1,
              closed: state.birdseye[campType].closed + 1,
            },
          }
          state.birdseye = birdseyeUpdate
        } else if (
          ['Conversing', 'Awaiting response', 'Out Of Office'].includes(
            action.payload.newStatus,
          )
        ) {
          birdseyeUpdate = {
            ...state.birdseye,
            [campType]: {
              ...state.birdseye[campType],
              requireAction: state.birdseye[campType].requireAction - 1,
              awaitingResponse: state.birdseye[campType].awaitingResponse + 1,
            },
          }
          state.birdseye = birdseyeUpdate
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTracking.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchTracking.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.leads = action.payload.resp1.leads
        state.emailConvoData = action.payload.resp1.emailConvoData
        if (action.payload.resp1.status === 'NO_DATA_FOUND') {
          state.counts = initialState.counts
        } else {
          state.counts = {
            ...action.payload.resp1.campCounts,
            conversing: action.payload.resp1.convoCounts['Conversing'],
            awaitingResponse: action.payload.resp1.convoCounts['Connected'],
            pendingConnection:
              action.payload.resp1.convoCounts['Pending Connection'],
            responseReady: action.payload.resp1.convoCounts['Response Ready'],
            requiresAction:
              action.payload.resp1.convoCounts['Attention:Not Interested'] +
              action.payload.resp1.convoCounts['Attention:Out Of Office'] +
              action.payload.resp1.convoCounts['Attention:Initiating  Call'] +
              action.payload.resp1.convoCounts['Attention:Needs Information'],
            all: action.payload.resp1.convoCounts['all'],
          }
        }
        var convos = {}
        Object.keys(action.payload.resp2.conversations).forEach(function (
          recipient,
        ) {
          const convo_events = JSON.parse(
            action.payload.resp2.conversations[recipient],
          )
          convos[recipient] = convo_events
        })
        // var addresses = JSON.parse(action.payload.resp2.addressbook);
        // addresses = addresses.filter((x) => Object.keys(convos).includes(x.recipient_urn));
        state.conversations = convos
        state.scheduledLog = action.payload.resp2.scheduled_log
        // state.sentLog = action.payload.resp2.sent_log;
        state.responses = action.payload.resp2.responses
        var actionItems = []
        state.threads_data = JSON.parse(action.payload.resp1.threads_data)
        state.addressbook = JSON.parse(action.payload.resp1.addressbook)

        // state.addressbook = addresses;
        if (action.payload.resp1.status === 'NO_DATA_FOUND') {
          state.birdseye = initialState.birdseye
        } else {
          state.birdseye = JSON.parse(action.payload.resp1.threads_data).reduce(
            (acc, v) => {
              switch (v.conversation_status) {
                case 'Pending Connection':
                case 'Awaiting response':
                case 'Out Of Office':
                  return {
                    ...acc,
                    [v.last_channel]: {
                      ...acc[v.last_channel],
                      onAutomated: acc[v.last_channel].onAutomated + 1,
                    },
                  }
                case 'Conversing':
                  return {
                    ...acc,
                    [v.last_channel]: {
                      ...acc[v.last_channel],
                      awaitingResponse:
                        acc[v.last_channel].awaitingResponse + 1,
                    },
                  }
                case 'Connected':
                  if (v.mild_complete) {
                    return {
                      ...acc,
                      [v.last_channel]: {
                        ...acc[v.last_channel],
                        awaitingResponse:
                          acc[v.last_channel].awaitingResponse + 1,
                      },
                    }
                  } else {
                    return {
                      // STILL SENDING MILDS
                      ...acc,
                      [v.last_channel]: {
                        ...acc[v.last_channel],
                        onAutomated: acc[v.last_channel].onAutomated + 1,
                      },
                    }
                  }
                case 'Attention:Not Interested':
                case 'Attention:Needs Information':
                case 'Attention:Out Of Office':
                case 'Attention:Initiating Call':
                case 'Response Ready':
                  actionItems.push(v)
                  return {
                    ...acc,
                    [v.last_channel]: {
                      ...acc[v.last_channel],
                      requireAction: acc[v.last_channel].requireAction + 1,
                    },
                  }
                case 'Closed Rejected':
                case 'Closed Accepted':
                  return {
                    ...acc,
                    [v.last_channel]: {
                      ...acc[v.last_channel],
                      closed: acc[v.last_channel].closed + 1,
                    },
                  }
                default:
                  return acc
              }
            },
            {
              linkedin: {
                requireAction: 0,
                awaitingResponse: 0,
                onAutomated: 0,
                closed: 0,
              },
              email: {
                requireAction: 0,
                awaitingResponse: 0,
                onAutomated: 0,
                closed: 0,
              },
            },
          )
        }
        state.actionItems = actionItems
      })
      .addCase(fetchTracking.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

export default slice.reducer
export const { updateActionItems } = slice.actions

// ----------------------------------------------------------------------

// export const UpdateReadStatus = async (token, recipient) => {
//   const data = new FormData();
//   data.append("recipient", recipient.recipient_urn);
//   data.append("campaignType", recipient.campaignType);
//   axios.post(process.env.REACT_APP_BACKEND_URL + "/update-read-status", data, {
//       headers: { authorization: token }
//     }).then((resp) => {
//       if (resp.data.status === 'success') {
//         dispatch(slice.actions.updateReadStatus(recipient));
//       }
//     });
//   return true;
// };
export const GetActivityLog = async (token, recipient_id) => {
  const data = new FormData()
  data.append('recipient_id', recipient_id)
  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/conversations/get-activity-log',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status === 'success') {
    dispatch(slice.actions.updateActivityLog(resp.data))
    return resp.data
  }
}

// ============================= RESPONSES =============================
export const updateResponse = async (token, newMsg, msgID, recipient) => {
  const data = new FormData()
  data.append('recipientURN', recipient)
  data.append('newMessage', newMsg)
  data.append('msgId', msgID === undefined ? -1 : msgID)

  axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/conversations/update-response',
      data,
      {
        headers: { authorization: token },
      },
    )
    .then(resp => {
      dispatch(slice.actions.updateResp(resp.data.responses))
    })
  return true
}

export const sendResponse = async (
  token,
  msgID,
  recipient,
  recipientName,
  message,
) => {
  // enqueueSnackbar('Queuing message to be sent ...', { variant: 'info' })
  dispatch(
    openSnackbar({
      open: true,
      message: 'Queuing message to be sent ...',
      variant: 'alert',
      alert: {
        color: 'info',
      },
      close: false,
    }),
  )

  const data = new FormData()
  data.append('msgID', msgID === undefined ? -1 : msgID)
  data.append('message', message)
  data.append('recipient', recipient)

  const resp = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/conversations/send-response',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(function (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with sending the message to ${recipientName}`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with sending the message to ${recipientName}`,
      //   false,
      //   {
      //     action: 'send-response',
      //     id: msgID,
      //     recipient: recipient,
      //     recipientName: recipientName,
      //   },
      // )
      return false
    })
  if (resp.data.status === 'success') {
    dispatch(slice.actions.updateResp(resp.data.responses))
    dispatch(slice.actions.updateConvo(resp.data.conversations))
    dispatch(
      slice.actions.updateActionItems({
        recipient: recipient,
        newStatus: 'Conversing',
      }),
    )
    dispatch(
      openSnackbar({
        open: true,
        message: `Message to ${recipientName} is queued to send!`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'success',
    //   `Message to ${recipientName} is queued to send!`,
    //   false,
    //   {
    //     action: 'send-response',
    //     id: msgID,
    //     recipient: recipient,
    //     recipientName: recipientName,
    //   },
    // )
    return true
  }
}

export const SendEmailResponse = async (
  token,
  responseToSend,
  replyToThread,
  recipient_name,
  campaign,
  attachments,
) => {
  const data = new FormData()
  // INFO ABOUT EXSITING THREAD TO REPLY TO
  data.append('recipientEmail', replyToThread.recipient_email)
  data.append('ccedEmails', JSON.stringify(replyToThread.cced_emails))
  data.append('threadID', replyToThread.thread_id)
  data.append('subject', replyToThread.subject)
  data.append('campaign', campaign)
  // INFO ABOUT RESPONSE TO SEND
  data.append('msgID', responseToSend.msgID)
  data.append('body', responseToSend.body)
  data.append('attachments', JSON.stringify(attachments))

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/conversations/send-email-response',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(function (error) {
      if (typeof error.response !== 'undefined') {
        dispatch(
          openSnackbar({
            open: true,
            message: `Something went wrong with submitting send for ${recipient_name}; Try again later`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Something went wrong with submitting send for ${recipient_name}; Try again later`,
        //   false,
        //   {
        //     action: 'send-email-response',
        //     responseToSend: JSON.stringify(responseToSend),
        //     replyToThread: JSON.stringify(replyToThread),
        //     recipient_name: recipient_name,
        //     campaign: campaign,
        //   },
        // )
        return ''
      }
    })

  if (msg.data.status === 'fail') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Something went wrong with submitting send for ${recipient_name}; Try again later`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `Something went wrong with submitting send for ${recipient_name}; Try again later`,
    //   false,
    //   {
    //     action: 'send-email-response',
    //     responseToSend: JSON.stringify(responseToSend),
    //     replyToThread: JSON.stringify(replyToThread),
    //     recipient_name: recipient_name,
    //     campaign: campaign,
    //   },
    // )
    return ''
  } else {
    // SUCCESS
    // UpdateEmailThreads(token);
    dispatch(
      openSnackbar({
        open: true,
        message: `Your response to ${recipient_name} is queued to send! You will see it in the threads once it finished sending.`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )

    dispatch(slice.actions.updateResp(msg.data.responses))
    // dispatch(
    //   slice.actions.updateActionItems({
    //     recipient: replyToThread.recipient_email,
    //     newStatus: 'Conversing',
    //   }),
    // )

    // the above doesn't work, so i disabled it for now

    // PushNotification(
    //   token,
    //   'success',
    //   `Your response to ${recipient_name} is queued to send! You will see it in the threads once it finished sending.`,
    //   false,
    //   {
    //     action: 'send-email-response',
    //     responseToSend: JSON.stringify(responseToSend),
    //     replyToThread: JSON.stringify(replyToThread),
    //     recipient_name: recipient_name,
    //     campaign: campaign,
    //   },
    // )
    return 'success'
  }
}

export const GetEmailFromLinkedin = async (
  token,
  recipient_name,
  recipient_public_id,
  recipient_company,
) => {
  const data = new FormData()
  data.append('recipient_name', recipient_name)
  data.append('recipient_public_id', recipient_public_id)
  data.append('recipient_company', recipient_company)

  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL +
      '/conversations/get-email-from-linkedin',
    data,
    {
      headers: { authorization: token },
    },
  )
  return resp.data
}

export const ContinueConvoByEmail = async (
  token,
  recipient,
  continueEmail,
  attachments,
) => {
  const data = new FormData()
  data.append('recipient', recipient)
  data.append('continueEmail', JSON.stringify(continueEmail))
  data.append('attachments', JSON.stringify(attachments))

  axios
    .post(
      process.env.REACT_APP_BACKEND_URL +
        '/conversations/continue-linkedin-conversation-by-email',
      data,
      {
        headers: { authorization: token },
      },
    )
    .then(resp => {
      if (resp.data.status === 'success') {
        // dispatch(slice.actions.updateActionItems({recipient: recipient, newStatus: "Closed Continued"}));
        // dispatch(slice.actions.updateActionItems({recipient: continueEmail, newStatus: "Conversing"}));
        dispatch(
          openSnackbar({
            open: true,
            message: `Sent a new email!`,
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
        // PushNotification(token, 'success', `Sent a new email!`, false, {
        //   action: 'continue-conversation-email',
        // })
        return 'success'
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Failed to send new email`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(token, 'error', `Failed to send new email`, false, {
        //   action: 'continue-conversation-email',
        // })
        return 'error'
      }
    })
}

export const ChangeConvoStatus = async (
  token,
  recipient,
  channelType,
  recipientName,
  closeStatus,
) => {
  const data = new FormData()
  data.append('recipient', recipient)
  data.append('channelType', channelType)
  data.append('closeStatus', closeStatus)

  axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/conversations/close-conversation',
      data,
      {
        headers: { authorization: token },
      },
    )
    .then(resp => {
      if (resp.data.status === 'success') {
        dispatch(
          slice.actions.updateActionItems({
            recipient: recipient,
            newStatus: closeStatus,
          }),
        )
        dispatch(
          openSnackbar({
            open: true,
            message: `Sucessfully updated conversation status for ${recipientName}`,
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'success',
        //   `Closed and archived conversation with ${recipientName}`,
        //   false,
        //   {
        //     action: 'close-conversation',
        //     recipient: recipient,
        //     campaignType: campaignType,
        //     recipientName: recipientName,
        //     closeStatus: closeStatus,
        //   },
        // )
        return 'success'
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Could not update conversation status for ${recipientName}`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Could not close conversation with ${recipientName}`,
        //   false,
        //   {
        //     action: 'close-conversation',
        //     recipient: recipient,
        //     campaignType: campaignType,
        //     recipientName: recipientName,
        //     closeStatus: closeStatus,
        //   },
        // )
        return 'error'
      }
    })
}

export const CloseConversation = async (
  token,
  recipient,
  channelType,
  recipientName,
  closeStatus,
) => {
  const data = new FormData()
  data.append('recipient', recipient)
  data.append('channelType', channelType)
  data.append('closeStatus', closeStatus)

  axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/conversations/close-conversation',
      data,
      {
        headers: { authorization: token },
      },
    )
    .then(resp => {
      if (resp.data.status === 'success') {
        dispatch(
          slice.actions.updateActionItems({
            recipient: recipient,
            newStatus: closeStatus,
          }),

          dispatch(
            openSnackbar({
              open: true,
              message: `Closed and archived conversation with ${recipientName}`,
              variant: 'alert',
              alert: {
                color: 'success',
              },
              close: false,
            }),
          ),
        )
        // PushNotification(
        //   token,
        //   'success',
        //   `Closed and archived conversation with ${recipientName}`,
        //   false,
        //   {
        //     action: 'close-conversation',
        //     recipient: recipient,
        //     campaignType: campaignType,
        //     recipientName: recipientName,
        //     closeStatus: closeStatus,
        //   },
        // )
        return 'success'
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: `Could not close conversation with ${recipientName}`,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        // PushNotification(
        //   token,
        //   'error',
        //   `Could not close conversation with ${recipientName}`,
        //   false,
        //   {
        //     action: 'close-conversation',
        //     recipient: recipient,
        //     campaignType: campaignType,
        //     recipientName: recipientName,
        //     closeStatus: closeStatus,
        //   },
        // )
        return 'error'
      }
    })
}

export const FetchMildsForConversing = async (
  token,
  recipient,
  campaignType,
  recipientName,
) => {
  const data = new FormData()
  data.append('recipient', recipient)
  data.append('recipient_name', recipientName)
  data.append('campaignType', campaignType)

  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL +
      '/conversations/fetch-milds-for-conversing',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status !== 'success') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Could not reschedule messages for  ${recipientName}`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    return 'error'
  } else {
    return resp.data.pendings
  }
}

export const SetOOOAutoResume = async (
  token,
  recipient,
  campaignType,
  pendings,
  returnDate,
  recipientName,
  channel,
) => {
  const data = new FormData()
  data.append('recipient', recipient)
  data.append('campaignType', campaignType)
  data.append('pendings', JSON.stringify(pendings))
  data.append('return_date', returnDate.format('YYYY-MM-DD'))
  data.append('recipient_name', recipientName)
  data.append('channel', channel)

  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/conversations/set-ooo-auto-resume',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status !== 'success') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Could not reschedule messages for  ${recipientName}`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    return 'error'
  } else {
    dispatch(
      slice.actions.updateActionItems({
        recipient: recipient,
        newStatus: 'Out Of Office',
      }),
    )
    dispatch(
      openSnackbar({
        open: true,
        message: `Successfully rescheduled messages for  ${recipientName}`,
        variant: 'success',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    return 'success'
  }
}

export const ResumeOOOSequence = async (
  token,
  recipient,
  campaignType,
  pendings,
  recipientName,
  channel,
) => {
  const data = new FormData()
  data.append('recipient', recipient)
  data.append('campaignType', campaignType)
  data.append('pendings', JSON.stringify(pendings))
  data.append('recipient_name', recipientName)
  data.append('channel', channel)

  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/conversations/resume-ooo-sequence',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status !== 'success') {
    dispatch(
      openSnackbar({
        open: true,
        message: `Could not resume sequence for  ${recipientName}`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    return 'error'
  } else {
    dispatch(
      slice.actions.updateActionItems({
        recipient: recipient,
        newStatus: 'Awaiting response',
      }),
    )
    dispatch(
      openSnackbar({
        open: true,
        message: `Successfully resumed sequence for  ${recipientName}`,
        variant: 'success',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    return 'success'
  }
}
