import LAYOUT_CONST from 'constant'

export const JWT_API = {
  secret: 'SECRET-KEY',
  timeout: '1 days',
}

export const FIREBASE_API = {
  apiKey: 'AIzaSyB37AcsU40p_3ipHq2vQtZRzxrlHz0QggQ',
  authDomain: 'rocketbrew-linkedin.firebaseapp.com',
  projectId: 'rocketbrew-linkedin',
  storageBucket: 'rocketbrew-linkedin.appspot.com',
  messagingSenderId: '30487149423',
  appId: '1:30487149423:web:6b8bcff6a3de4e784b469a',
  measurementId: 'G-1RVT6P1G57',
}

export const AUTH0_API = {
  client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
  domain: 'dev-w0-vxep3.us.auth0.com',
}

export const AWS_API = {
  poolId: 'us-east-1_AOfOTXLvD',
  appClientId: '3eau2osduslvb7vks3vsh9t7b0',
}

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = ''

export const DASHBOARD_PATH = '/'

export const HORIZONTAL_MAX_ITEM = 6

const config = {
  layout: LAYOUT_CONST.HORIZONTAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
}

export default config
