import { lazy } from 'react'
import { useRoutes, Route } from 'react-router-dom'

import Loadable from 'ui-component/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'
import GuestGuard from 'utils/route-guard/GuestGuard'

import MainLayout from 'layout/MainLayout'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'

// login routing
const Login = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/Login')),
)
const Register = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/Register')),
)
const ForgotPassword = Loadable(
  lazy(() =>
    import('views/pages/authentication/auth-forms/AuthForgotPassword'),
  ),
)
const ResetPassword = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/AuthResetPassword')),
)
const Unsubscribe = Loadable(lazy(() => import('views/pages/unsubscribe')))
const AccountSetupWizard = Loadable(
  lazy(() => import('views/pages/authentication/AccountSetupWizard')),
)

const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')))
const Setup = Loadable(lazy(() => import('views/pages/setup')))
const GenerateMessages = Loadable(
  lazy(() => import('views/pages/generate-messages')),
)
const SendOutreach = Loadable(lazy(() => import('views/pages/send-outreach')))
const Support = Loadable(lazy(() => import('views/pages/support')))
const TrackConvo = Loadable(
  lazy(() => import('views/pages/track-conversations')),
)
const Calendar = Loadable(lazy(() => import('views/pages/calendar')))
const Error = Loadable(lazy(() => import('views/pages/maintenance/Error')))
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    // AUTH ROUTES
    {
      path: '/',
      element: (
        <NavMotion>
          <GuestGuard>
            <MinimalLayout />
          </GuestGuard>
        </NavMotion>
      ),
      children: [
        {
          path: '/',
          element: <Login />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/register',
          element: <Register />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        { path: '/unsubscribe', element: <Unsubscribe /> },
      ],
    },
    // ONBOARDING ROUTES
    {
      path: '/',
      element: (
        <AuthGuard>
          <MinimalLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/onboarding',
          element: <AccountSetupWizard />,
          children: [
            {
              path: '/onboarding/choose-plan',
            },
            {
              path: '/onboarding/company',
            },
            {
              path: '/onboarding/region',
            },
            {
              path: '/onboarding/linkedin',
            },
            {
              path: '/onboarding/model-settings',
            },
            {
              path: '/onboarding/complete',
            },
          ],
        },
      ],
    },
    // MAIN ROUTES
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/analytics',
          element: <Dashboard />,
        },
        {
          path: '/settings',
          element: <Setup />,
          children: [
            {
              path: '/settings/account',
            },
            {
              path: '/settings/automation',
            },
            {
              path: '/settings/lead-search',
            },
            {
              path: '/settings/generation-model',
            },
            {
              path: '/settings/outreach-model',
            },
            {
              path: '/settings/conversation-model',
            },
            {
              path: '/settings/linkedin',
            },
            {
              path: '/settings/email',
            },
            {
              path: '/settings/calendar',
            },
            {
              path: '/settings/knowledge-base',
            },
            {
              path: '/settings/notification',
            },
          ],
        },
        {
          path: '/generate-messages',
          element: <GenerateMessages />,
        },
        {
          path: '/send-outreach',
          element: <SendOutreach />,
        },
        {
          path: '/conversations',
          element: <TrackConvo />,
        },
        {
          path: '/calendar',
          element: <Calendar />,
        },
        {
          path: '/support',
          element: <Support />,
        },
      ],
    },
    {
      path: '*',
      element: <Error source='path' />,
    },
  ])
}
