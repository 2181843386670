import Dashboard from './Dashboard'
import GenerateMessages from './GenerateMessages'
import Setup from './Setup'
import SendOutreach from './SendOutreach'
import Calendar from './Calendar'
import Support from './Support'
import TrackConvo from './TrackConversations'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    GenerateMessages,
    SendOutreach,
    TrackConvo,
    Dashboard,
    Calendar,
    Setup,
    Support,
  ],
}

export default menuItems
