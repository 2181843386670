// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import ProfileSection from './ProfileSection'
import logo from 'assets/images/rocketbrew_logo.png'
import { Stack } from '@mui/system'
import packageJson from '../../../../package.json'
global.appVersion = packageJson.version

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('xs')]: {
            width: 'auto',
          },
        }}
      >
        <Stack direction='row' alignItems='center'>
          <Box component='span' sx={{ display: { xs: 'block' }, flexGrow: 1 }}>
            <img src={logo} height='40px' />
          </Box>
          {/* TODO: make this auto update in sync */}
          <Typography>v{global.appVersion}</Typography>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <ProfileSection />
    </>
  )
}

export default Header
