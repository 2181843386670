import { Link, useNavigate } from 'react-router-dom'

// material-ui
import { useTheme, styled } from '@mui/material/styles'
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material'

// project imports
import { DASHBOARD_PATH } from 'config'
import AnimateButton from 'ui-component/extended/AnimateButton'
import { gridSpacing } from 'store/constant'

// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'

import imageBackground from 'assets/images/maintenance/img-error-bg.svg'
import imageDarkBackground from 'assets/images/maintenance/img-error-bg-dark.svg'
import imageBlue from 'assets/images/maintenance/img-error-blue.svg'
import imageText from 'assets/images/maintenance/img-error-text.svg'
import imagePurple from 'assets/images/maintenance/img-error-purple.svg'

// styles
const CardMediaWrapper = styled('div')({
  maxWidth: 720,
  margin: '0 auto',
  position: 'relative',
})

const ErrorWrapper = styled('div')({
  maxWidth: 500,
  margin: '0 auto',
  textAlign: 'center',
})

const ErrorCard = styled(Card)({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
})

const CardMediaBlock = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '3s bounce ease-in-out infinite',
})

const CardMediaBlue = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '15s wings ease-in-out infinite',
})

const CardMediaPurple = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  animation: '12s wings ease-in-out infinite',
})

// ==============================|| ERROR PAGE ||============================== //

const Error = ({ resetErrorBoundary, source }) => {
  const navigate = useNavigate()
  return (
    <ErrorCard>
      <CardContent>
        <Grid container justifyContent='center' spacing={gridSpacing}>
          <Grid item xs={12}>
            <CardMediaWrapper>
              <CardMedia
                component='img'
                image={imageBackground}
                title='Slider5 image'
              />
              <CardMediaBlock src={imageText} title='Slider 1 image' />
              <CardMediaBlue src={imageBlue} title='Slider 2 image' />
              <CardMediaPurple src={imagePurple} title='Slider 3 image' />
            </CardMediaWrapper>
          </Grid>
          <Grid item xs={12}>
            <ErrorWrapper>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant='h4' component='div'>
                    Something went wrong ..
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    The page you are looking was moved, removed, renamed, or
                    might never exist!{' '}
                  </Typography>
                  <Typography variant='body2'>
                    If this page reoccurs, please contact{' '}
                    <Typography
                      onClick={() => {
                        window.open(
                          'mailto:help@therocketbrew.com?subject=I%20keep%20running%20into%20an%20error%20page&body=Please%20describe%20your%20actions%20up%20to%20the%20error%20page.%0DWe%20will%20get%20get%20back%20to%20you%20as%20soon%20as%20possible.%0D%0A%0D%0AName: %0D%0AEmail: %0D%0AWhat%20happened?: ',
                        )
                      }}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                      mt={1}
                      color='primary'
                      variant='body2'
                      display='inline'
                    >
                      help@therocketbrew.com
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      variant='contained'
                      size='large'
                      onClick={() => {
                        if (source === 'path') {
                          navigate('/')
                        } else {
                          resetErrorBoundary()
                        }
                      }}
                    >
                      <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} />{' '}
                      Return to App
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </ErrorWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ErrorCard>
  )
}

export default Error
