// third-party
import { FormattedMessage } from "react-intl";
import { IconCalendar } from "@tabler/icons";

// ==============================|| setup MENU ITEMS ||============================== //

const Calendar = {
  id: "Calendar",
  title: <FormattedMessage id="Calendar" />,
  icon: IconCalendar,
  type: "item",
  url: "/calendar",
};

export default Calendar;
