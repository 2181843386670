// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconHelp } from "@tabler/icons";

// ==============================|| setup MENU ITEMS ||============================== //

const Support = {
  id: "Support",
  title: <FormattedMessage id="Get Help" />,
  icon: IconHelp,
  type: "item",
  url: "/support",
};

export default Support;
