import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

// project imports
import axios from 'axios'
import { dispatch } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  events: [],
  calendarList: [],
  primary: '',
}

export const getCalendarEvents = createAsyncThunk(
  'getCalendarEvents',
  async token => {
    const resp_CalendarList = await axios.get(
      process.env.REACT_APP_BACKEND_URL + '/user/get-calendar-list',
      {
        headers: { authorization: token },
      },
    )
    let calendarList = resp_CalendarList.data
    let outlook = []
    let google = []
    let primary
    // [{'category': 'OUTLOOK_CALENDAR', 'key': 'gertude.florence@outlook.com'}]
    if (
      calendarList.filter(c => c.category === 'OUTLOOK_CALENDAR').length > 0
    ) {
      const resp_outlook = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          '/outlook-calendar/get-calendar-events',
        {
          headers: { authorization: token },
        },
      )
      outlook = resp_outlook.data.map(event => ({
        ...event,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        title: event.subject,
      }))
      primary = 'OUTLOOK_CALENDAR'
    }
    if (calendarList.filter(c => c.category === 'GOOGLE_CALENDAR').length > 0) {
      const resp_google = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          '/google-calendar/get-calendar-events',
        {
          headers: { authorization: token },
        },
      )
      google = resp_google.data.map(event => ({
        ...event,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        title: event.summary,
        guests: event.attendees,
        description: event.description,
      }))
      primary = 'GOOGLE_CALENDAR'
    }
    return {
      events: outlook.concat(google),
      calendarList: calendarList,
      primary: primary,
    }
  },
)

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.events = action.payload
    },

    // ADD EVENT
    addEventSuccess(state, action) {
      state.events = action.payload
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.events = action.payload
    },

    // REMOVE EVENT
    removeEventSuccess(state, action) {
      state.events = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCalendarEvents.pending, state => {
        state.status = 'loading'
      })
      .addCase(getCalendarEvents.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.events = action.payload.events
        state.calendarList = action.payload.calendarList
        state.primary = action.payload.primary
      })
      .addCase(getCalendarEvents.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export async function addEvent(token, values) {
  const data = new FormData()
  data.append('eventTitle', values.title)
  data.append('startsTimestamp', values.start.toISOString())
  data.append('endsTimestamp', values.end.toISOString())
  // data.append('location', values.location)
  data.append('description', values.description)
  data.append('attendeeEmails', JSON.stringify(values.guests))
  data.append('notification', values.notification)

  if (values.calendar.category === 'OUTLOOK_CALENDAR') {
    const resp = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/outlook-calendar/create-event',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(() => {
        dispatch(
          openSnackbar({
            open: true,
            message:
              'Something went wrong with creating your calendar invitation',
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        return false
      })
    if (resp.data.status === 'SUCCESS') {
      dispatch(getCalendarEvents(token)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully sent calendar invitation',
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
        return true
      })
    }
  } else if (values.calendar.category === 'GOOGLE_CALENDAR') {
    const resp = await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/google-calendar/create-event',
        data,
        {
          headers: { authorization: token },
        },
      )
      .catch(() => {
        dispatch(
          openSnackbar({
            open: true,
            message:
              'Something went wrong with creating your calendar invitation',
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          }),
        )
        return false
      })
    if (resp.data.status === 'SUCCESS') {
      dispatch(getCalendarEvents(token)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully sent calendar invitation',
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
        return true
      })
    }
  }
}

export async function updateEvent(token, event) {
  const data = new FormData()
  data.append('eventId', event.id)
  data.append('eventTitle', event.title)
  data.append('startsTimestamp', event.start.toISOString())
  data.append('endsTimestamp', event.end.toISOString())
  data.append('location', event.location)
  data.append('description', event.description)
  data.append('attendeeEmails', JSON.stringify(event.guests))

  if (event.provider === 'outlook') {
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/outlook-calendar/edit-event',
      data,
      {
        headers: { authorization: token },
      },
    )
    if (resp.data.status === 'SUCCESS') {
      dispatch(getCalendarEvents(token)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully updated calendar event details',
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
      })
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Something went wrong with updating your calendar event',
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
    }
  } else if (event.provider === 'google') {
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/google-calendar/edit-event',
      data,
      {
        headers: { authorization: token },
      },
    )
    if (resp.data.status === 'SUCCESS') {
      dispatch(getCalendarEvents(token)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully updated calendar event',
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
      })
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Something went wrong with updating your calendar event',
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
    }
  }
}

export async function removeEvent(token, provider, eventId) {
  const data = new FormData()
  data.append('eventId', eventId)

  if (provider === 'outlook') {
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/outlook-calendar/delete-event',
      data,
      {
        headers: { authorization: token },
      },
    )
    if (resp.data.status === 'SUCCESS') {
      dispatch(getCalendarEvents(token)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully deleted calendar event',
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
      })
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Something went wrong with deleting calendar event',
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
    }
  } else if (provider === 'google') {
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/google-calendar/delete-event',
      data,
      {
        headers: { authorization: token },
      },
    )

    if (resp.data.status === 'SUCCESS') {
      dispatch(getCalendarEvents(token)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully deleted calendar event',
            variant: 'alert',
            alert: {
              color: 'success',
            },
            close: false,
          }),
        )
      })
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Something went wrong with deleting calendar event',
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
    }
  }
}
