import { memo, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Typography, useMediaQuery } from '@mui/material'

// project imports
import menuItem from 'menu-items'
import NavGroup from './NavGroup'
import LAYOUT_CONST from 'constant'
import { HORIZONTAL_MAX_ITEM } from 'config'
import useConfig from 'hooks/useConfig'
import NavItem from './NavItem'
import TopLevelNavItem from './TopLevelNavItem'
import { fetchPendingSend } from 'store/slices/sendOutreach'
import { fetchAnalyticsData } from 'store/slices/analytics'
import { GetBalance, fetchUserInfo } from 'store/slices/user'
import {
  GetInboxList,
  getModelSettings,
  UpdateEmailThreads,
} from 'store/slices/modelSettings'
import { getCampaigns } from 'store/slices/campaigns'
import { fetchTracking } from 'store/slices/conversations'
import { useDispatch, useSelector } from 'store'
import useAuth from 'hooks/useAuth'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const theme = useTheme()
  const auth = useAuth()
  const teamMembers = useSelector(state => state.user.teamMembers)
  const userState = useSelector(state => state.user)
  const inboxList = useSelector(state => state.modelSettings.inboxList)
  const dispatch = useDispatch()

  useEffect(() => {
    auth.firebaseGetToken().then(token => {
      dispatch(fetchUserInfo(token))
      dispatch(fetchAnalyticsData(token, teamMembers))
      dispatch(getCampaigns(token))
      dispatch(getModelSettings(token))
      dispatch(fetchPendingSend(token))
      GetInboxList(token)
      UpdateEmailThreads(token)

      // dispatch(fetchConversations(token));
      dispatch(fetchTracking(token))
      if (typeof userState.maple.id !== 'undefined') {
        GetBalance(token, userState)
      }
    })

    const minuteInterval = setInterval(() => {
      auth.firebaseGetToken().then(token => {
        dispatch(fetchPendingSend(token))
      })
    }, 60000)
    // const tenMinuteInterval = setInterval(() => {
    //   if (inboxList.length > 0) {
    //     auth.firebaseGetToken().then((token) => {
    //       UpdateEmailThreads(token);
    //     })
    //   }
    // }, 600000);
    // }, 60000);
    const twentyMinuteInterval = setInterval(() => {
      auth.firebaseGetToken().then(token => {
        dispatch(fetchUserInfo(token))
      })
    }, 1200000)
    const thirtyMinuteInterval = setInterval(() => {
      auth.firebaseGetToken().then(token => {
        dispatch(fetchAnalyticsData(token, teamMembers))
        dispatch(fetchTracking(token))
      })
    }, 1800000)
    const hourlyInterval = setInterval(() => {
      auth.firebaseGetToken().then(token => {
        dispatch(getCampaigns(token))
        // dispatch(fetchConversations(token));
        dispatch(getModelSettings(token))
      })
    }, 3600000)

    return () => {
      clearInterval(minuteInterval)
      // clearInterval(tenMinuteInterval);
      clearInterval(twentyMinuteInterval)
      clearInterval(thirtyMinuteInterval)
      clearInterval(hourlyInterval)
    }
  }, [])

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { layout } = useConfig()

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null

  let lastItemIndex = menuItem.items.length - 1
  let remItems = []
  let lastItemId

  if (lastItem && lastItem < menuItem.items.length) {
    lastItemId = menuItem.items[lastItem - 1].id
    lastItemIndex = lastItem - 1
    remItems = menuItem.items
      .slice(lastItem - 1, menuItem.items.length)
      .map(item => ({
        title: item.title,
        elements: item.children,
      }))
  }

  const navItems = menuItem.items.map(item => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
          />
        )
      case 'item':
        return (
          <TopLevelNavItem
            key={item.id}
            item={item}
            level={1}
            parentId={item.id}
          />
        )
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        )
    }
  })

  return <>{navItems}</>
}

export default memo(MenuList)
