import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { dispatch } from '../index'
import { fetchTracking, updateActionItems } from './conversations'
import { PushNotification } from './user'
import { openSnackbar } from './snackbar'
// ----------------------------------------------------------------------

const initialState = {
  // BASIC
  calendar: '',
  // CRM
  crm: {},
  salesforce: {},
  hubspot: {},
  pipedrive: {},
  zoho: {},
  excludeSettings: [],
  excludeLeads: [],
  // OUTREACH
  keywords: [],
  usecase: [],
  buysig: [],
  // CONVERSATION
  oneline: '',
  competition: [],
  faq: [],
  // LINKEDIN
  // EMAIL
  inboxList: [],
  signature: '',
  spf: '',
  ids: {},
  status: 'idle',
  error: null,
  // CALENDAR
  calendarList: [],
}

export const getModelSettings = createAsyncThunk(
  'getModelSettings',
  async token => {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + '/model_settings/get-settings-basic',
      {
        headers: { authorization: token },
      },
    )
    return resp.data
  },
)

const slice = createSlice({
  name: 'modelSettings',
  initialState,
  reducers: {
    // BASIC
    updateHomepageState(state, action) {
      state.homepage = action.payload
    },
    updateCalendarState(state, action) {
      state.calendar = action.payload
    },
    // PIPEDRIVE
    updatePipedriveIntegration(state, action) {
      state.pipedrive = action.payload
    },
    // HUBSPOT
    updateHubSpotIntegration(state, action) {
      state.hubspot = action.payload
    },
    // ZOHO
    updateZohoIntegration(state, action) {
      state.zoho = action.payload
    },
    // SALESFORCE
    updateSalesforceIntegration(state, action) {
      state.salesforce = action.payload
    },
    updateExcludeSettingsState(state, action) {
      state.excludeSettings = action.payload
    },
    updateExcludeLeads(state, action) {
      state.excludeLeads = action.payload
    },
    // MODEL
    updateModelState(state, action) {
      state.model = action.payload
    },
    updateOnelineState(state, action) {
      state.oneline = action.payload
    },
    updateValuePropState(state, action) {
      state.valueProp = action.payload
    },
    updateKeywordsState(state, action) {
      state.keywords = action.payload
    },
    updateUseCaseState(state, action) {
      state.usecase = action.payload
    },
    updateBuySigState(state, action) {
      state.buysig = action.payload
    },
    updateCompetitionState(state, action) {
      state.competition = action.payload
    },
    updateFaqState(state, action) {
      state.faq = action.payload
    },
    // updateInboxList(state, action) {
    //     state.inboxList = [{email: state.inboxList[0].email, lastUpdated: action.payload}]
    // },
    updateInboxList(state, action) {
      state.inboxList = action.payload
    },
    updateSignatureState(state, action) {
      state.signature = action.payload
    },
    updateCalendarList(state, action) {
      state.calendarList = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getModelSettings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(getModelSettings.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // BASIC
        state.homepage = action.payload.homepage
        state.calendar = action.payload.calendar
        // PIPEDRIVE
        state.pipedrive = action.payload.pipedrive
        // ZOHO
        state.zoho = action.payload.zoho
        // HUBSPOT
        state.hubspot = action.payload.hubspot
        // SALESFORCE
        state.crm = action.payload.crm
        state.salesforce = action.payload.salesforce
        state.excludeSettings = action.payload.excludeSettings
        state.excludeLeads = action.payload.excludeLeads
        // OUTREACH
        state.keywords = action.payload.keywords
        state.usecase = action.payload.usecase
        state.buysig = action.payload.buysig
        // CONVERSATION
        state.oneline = action.payload.oneline
        state.competition = action.payload.competition
        state.faq = action.payload.faq
        // LINKEDIN
        // CALENDAR
        state.calendarList = action.payload.calendarList
        // EMAIL
        state.inboxList = action.payload.inboxList
        state.signature = action.payload.signature
        state.spf = action.payload.spf
        state.ids = action.payload.ids
      })
      .addCase(getModelSettings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(PURGE, () => {
        return initialState
      })
  },
})

// Reducer
export default slice.reducer
export const {
  updatePipedriveIntegration,
  updateHubSpotIntegration,
  updateZohoIntegration,
  updateSalesforceIntegration,
  updateCalendarState,
  updateModelState,
  updateValuePropState,
  updateInboxList,
  updateCalendarList,
} = slice.actions

export const UpdateHomepage = async (token, homepage) => {
  const data = new FormData()
  data.append('homepage', homepage)

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL +
        '/model_settings/update-company-homepage',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with saving your changes`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with saving your changes`,
      //   false,
      //   { action: 'save-changes-basic', homepage: homepage },
      // )

      return ''
    })
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateHomepageState(homepage))
    dispatch(
      openSnackbar({
        open: true,
        message: `Saved changes to company homepage`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'success',
    //   `Saved changes to company homepage`,
    //   false,
    //   { action: 'update-company-homepage', homepage: homepage },
    // )
  }
}

export const SaveChangesBasic = async (token, state, fields) => {
  const data = new FormData()
  data.append('ids', JSON.stringify(state.ids))
  // Update only if there were changes
  if ('calendar' in fields && fields.calendar !== state.calendar) {
    data.append('calendar', fields.calendar)
  }

  if ('model' in fields && fields.model !== state.model) {
    data.append(
      'model',
      fields.model.modelName.concat(fields.model.emoji ? '' : '-noemoji'),
    )
  }

  if ('valueProp' in fields && fields.valueProp !== state.valueProp) {
    data.append('valueProp', fields.valueProp)
  }
  if ('oneline' in fields && fields.oneline !== state.oneline) {
    data.append('oneline', fields.oneline)
  }
  if ('signature' in fields && fields.signature !== state.signature) {
    data.append('signature', fields.signature)
  }

  const msg = await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + '/model_settings/save-changes-basic',
      data,
      {
        headers: { authorization: token },
      },
    )
    .catch(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Something went wrong with saving your changes`,
          variant: 'alert',
          alert: {
            color: 'error',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'error',
      //   `Something went wrong with saving your changes`,
      //   false,
      //   { action: 'save-changes-basic', fields: JSON.stringify(fields) },
      // )
      return ''
    })
  if (msg.data.status === 'success') {
    if ('calendar' in fields) {
      dispatch(slice.actions.updateCalendarState(fields.calendar))
    }
    if ('model' in fields) {
      dispatch(slice.actions.updateModelState(fields.model))
    }
    if ('valueProp' in fields) {
      dispatch(slice.actions.updateValuePropState(fields.valueProp))
    }
    if ('oneline' in fields) {
      dispatch(slice.actions.updateOnelineState(fields.oneline))
    }
    if ('signature' in fields) {
      dispatch(slice.actions.updateSignatureState(fields.signature))
    }
    dispatch(
      openSnackbar({
        open: true,
        message: `Saved changes to settings`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(token, 'success', `Saved changes to settings`, false, {
    //   action: 'save-changes-basic',
    //   fields: JSON.stringify(fields),
    // })
  }
}

export const UpdateSalesforceFieldMap = async (token, fieldMap) => {
  const data = new FormData()
  data.append('fieldMap', fieldMap)
  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/salesforce/update-sf-field-map',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateSalesforceIntegration(msg.data.salesforce))
    dispatch(
      openSnackbar({
        open: true,
        message: `Successfully mapped Salesforce field for LinkedIn URL`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'success',
    //   `Successfully mapped Salesforce field for LinkedIn URL`,
    //   false,
    //   { action: 'update-sf-field-map', fieldMap: fieldMap },
    // )
    return true
  } else if (msg.data.status === 'ERR_INVALID_FIELD') {
    dispatch(
      openSnackbar({
        open: true,
        message: `${fieldMap} is an invalid field / Double check and resubmit`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `${fieldMap} is an invalid field / Double check and resubmit`,
    //   false,
    //   { action: 'update-sf-field-map', fieldMap: fieldMap },
    // )
    return false
  }
}

export const UpdateExcludeSetting = async (token, update) => {
  const data = new FormData()
  data.append('update', JSON.stringify(update))
  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL +
      '/model_settings/update-exclude-setting',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateExcludeSettingsState(update))
  }
}

export const AddExcludeLead = async (token, mode, leads) => {
  const data = new FormData()
  data.append('leads', JSON.stringify(leads))
  data.append('mode', mode)

  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/model_settings/add-exclude-lead',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateExcludeLeads(msg.data.excludeLeads))
  }
}

export const DeleteExcludeLead = async (token, selected) => {
  const data = new FormData()
  data.append('selected', JSON.stringify(selected))

  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/model_settings/delete-exclude-lead',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateExcludeLeads(msg.data.excludeLeads))
  }
}

export const UpdateKeywords = async (token, state, keywords) => {
  if (new Set(keywords) !== new Set(state.keywords)) {
    const data = new FormData()
    data.append('keywords', JSON.stringify(keywords))
    data.append('id', state.ids.keywords)
    const msg = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/model_settings/update-keywords',
      data,
      {
        headers: { authorization: token },
      },
    )
    if (msg.data.status === 'success') {
      dispatch(slice.actions.updateKeywordsState(msg.data.keywords))
    }
  }
}

export const AddFAQFromTracking = async (
  token,
  question,
  answer,
  recipient,
) => {
  const data = new FormData()
  data.append('question', question)
  data.append('answer', answer)

  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/model_settings/add-faq-from-tracking',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    dispatch(slice.actions.updateFaqState(msg.data.update))
    dispatch(
      updateActionItems({ recipient: recipient, newStatus: 'Conversing' }),
    )
    dispatch(
      openSnackbar({
        open: true,
        message: `Successfully added new FAQ`,
        variant: 'alert',
        alert: {
          color: 'success',
        },
        close: false,
      }),
    )
    // PushNotification(token, 'success', `Successfully added new FAQ`, false, {
    //   action: 'add-faq-from-tracking',
    //   question: question,
    //   answer: answer,
    //   recipient: recipient,
    // })
  }
}

export const UpdateMultiSetting = async (token, category, rows) => {
  const data = new FormData()
  data.append('category', category)
  data.append('rows', JSON.stringify(rows))
  const msg = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/model_settings/update-multi-setting',
    data,
    {
      headers: { authorization: token },
    },
  )
  if (msg.data.status === 'success') {
    if (category === 'COMPETITION') {
      dispatch(slice.actions.updateCompetitionState(msg.data.update))
    } else if (category === 'FAQ') {
      dispatch(slice.actions.updateFaqState(msg.data.update))
    } else if (category === 'USECASE') {
      dispatch(slice.actions.updateUseCaseState(msg.data.update))
    } else if (category === 'BUYINGSIGNAL') {
      dispatch(slice.actions.updateBuySigState(msg.data.update))
    }
    return msg.data.update
  }
}

export const DeleteMultiSetting = async (token, category, id) => {
  if (typeof id === 'number') {
    const data = new FormData()
    data.append('category', category)
    data.append('id', id)
    const msg = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        '/model_settings/delete-multi-setting',
      data,
      {
        headers: { authorization: token },
      },
    )
    if (msg.data.status === 'success') {
      if (category === 'COMPETITION') {
        dispatch(slice.actions.updateCompetitionState(msg.data.update))
      } else if (category === 'FAQ') {
        dispatch(slice.actions.updateFaqState(msg.data.update))
      } else if (category === 'USECASE') {
        dispatch(slice.actions.updateUseCaseState(msg.data.update))
      } else if (category === 'BUYINGSIGNAL') {
        dispatch(slice.actions.updateBuySigState(msg.data.update))
      }
      return msg.data.update
    }
  }
}

// =================================== CALENDARS ===================================

export const GetCalendarList = async token => {
  const resp = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/user/get-calendar-list',
    {
      headers: { authorization: token },
    },
  )
  dispatch(slice.actions.updateCalendarList(resp.data))
  return resp.data
}

// =================================== EMAIL GMAIL ===================================

export const GetInboxList = async token => {
  const resp = await axios.get(
    process.env.REACT_APP_BACKEND_URL + '/user/get-inbox-list',
    {
      headers: { authorization: token },
    },
  )
  dispatch(slice.actions.updateInboxList(resp.data))
  return resp.data
}

export const UpdateEmailThreads = async token => {
  axios
    .get(process.env.REACT_APP_BACKEND_URL + '/gmail/update-email-threads', {
      headers: { authorization: token },
    })
    .then(resp => {
      // UPDATE LAST DATA PULL TIMESTAMP
      // dispatch(slice.actions.updateInboxList(resp.data.lastUpdated));
      // RE-PULL LATEST CONVO DATA
      dispatch(fetchTracking(token))
    })
  return true
}

export const DisconnectCalendar = async (token, calendarEmail) => {
  const data = new FormData()
  data.append('calendarEmail', calendarEmail)

  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/user/disconnect-calendar',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status === 'success') {
    GetCalendarList(token).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Successfully disconnected your calendar`,
          variant: 'alert',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'success',
      //   `Successfully disconnected your calendar`,
      //   false,
      //   { action: 'disconnect-calendar', calendarEmail: calendarEmail },
      // )
    })
  } else {
    dispatch(
      openSnackbar({
        open: true,
        message: `Something went wrong while disconnecting your calendar`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `Something went wrong while disconnecting your calendar`,
    //   false,
    //   { action: 'disconnect-calendar', calendarEmail: calendarEmail },
    // )
  }
  return resp.data
}

export const DisconnectInbox = async (token, inboxEmail) => {
  const data = new FormData()
  data.append('inboxEmail', inboxEmail)

  const resp = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/user/disconnect-inbox',
    data,
    {
      headers: { authorization: token },
    },
  )

  if (resp.data.status === 'success') {
    GetInboxList(token).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Successfully disconnected your inbox`,
          variant: 'alert',
          alert: {
            color: 'success',
          },
          close: false,
        }),
      )
      // PushNotification(
      //   token,
      //   'success',
      //   `Successfully disconnected your inbox`,
      //   false,
      //   { action: 'disconnect-inbox', inboxEmail: inboxEmail },
      // )
    })
  } else {
    dispatch(
      openSnackbar({
        open: true,
        message: `Something went wrong while disconnecting your inbox`,
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: false,
      }),
    )
    // PushNotification(
    //   token,
    //   'error',
    //   `Something went wrong while disconnecting your inbox`,
    //   false,
    //   { action: 'disconnect-inbox', inboxEmail: inboxEmail },
    // )
  }
  return resp.data
}
