// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconMessages } from "@tabler/icons";

// ==============================|| setup MENU ITEMS ||============================== //

const ConvoTracking = {
  id: "TrackConvo",
  title: <FormattedMessage id="Track Conversations" />,
  icon: IconMessages,
  type: "item",
  url: "/conversations",
  badge: true
};

export default ConvoTracking;
