import { createRoot } from 'react-dom/client'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// project imports
import App from 'App'
import { BASE_PATH } from 'config'
import { store, persister } from 'store'
import * as serviceWorker from 'serviceWorker'
import reportWebVitals from 'reportWebVitals'

// style + assets
import 'assets/scss/style.scss'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'views/pages/maintenance/Error'
// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={BASE_PATH}>
        <ErrorBoundary
          FallbackComponent={ErrorPage}
          onReset={() => (window.location.href = '/')}
        >
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
