import PropTypes from 'prop-types'
import { createContext, useEffect, useReducer } from 'react'

// third-party
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// action - state management
import { LOGIN, LOGOUT } from 'store/actions'
import accountReducer from 'store/accountReducer'

// project imports
import Loader from 'ui-component/Loader'
import { FIREBASE_API } from 'config'

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API)
}

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
}

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null)

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState)

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.uid,
                email: user.email,
                name: user.displayName || 'John Doe',
              },
            },
          })
        } else {
          dispatch({
            type: LOGOUT,
          })
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  )

  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password)
  // firebase
  //   .auth()
  //   .signInWithCustomToken(
  //     'eyJhbGciOiAiUlMyNTYiLCAidHlwIjogIkpXVCIsICJraWQiOiAiZmYwZjNmODEwNzQ1YjA5NmY3YWQzMmY3NDkxNmYwNGE5YTA5NzU1NSJ9.eyJpc3MiOiAiZmlyZWJhc2UtYWRtaW5zZGstZ2hvYzJAcm9ja2V0YnJldy1saW5rZWRpbi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsICJzdWIiOiAiZmlyZWJhc2UtYWRtaW5zZGstZ2hvYzJAcm9ja2V0YnJldy1saW5rZWRpbi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsICJhdWQiOiAiaHR0cHM6Ly9pZGVudGl0eXRvb2xraXQuZ29vZ2xlYXBpcy5jb20vZ29vZ2xlLmlkZW50aXR5LmlkZW50aXR5dG9vbGtpdC52MS5JZGVudGl0eVRvb2xraXQiLCAidWlkIjogIktqYnIwbHRzT1NkblB4dTdyejdVaXhDbDlmVzIiLCAiaWF0IjogMTcyMTIzNzA0NSwgImV4cCI6IDE3MjEyNDA2NDV9.pMoJi7-xg2ytRsT6sp-raLv9zbfmuZe3IXVL42apLkdZRlnGfzNBF92QCAJgPPsBktyN6rnkaCyqmbl84trCheFu-y0UJAfQEuRRfkMJ9A1PTsfER-AGQoNXTRx8uSgJb6Eb3fKSZgb0rnYoR22T7vHM4-iifdik1Iyl1m1XS9ygU_6M-Iv8WaxwnNBGtMAJH2uMFkfYq6uBHYNwggtwfSsIZp3QvHcRpHsOZM_j7NS2SeEqmcq-p9vwO-thyZX_QzoUZvyaePEn3HiLR9snlm4u78YJ42h2ZMpS1vxyqqBC6CpNTbkCIMVkgSI_UTBMy2uZ43ov8Pye0qveDQtAXw',
  //   )

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider()

    return firebase.auth().signInWithPopup(provider)
  }

  const firebaseRegister = async (email, password, username) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async () => {
        const user = firebase.auth().currentUser
        await user.updateProfile({
          displayName: username,
        })

        // Log the user out
        await firebase.auth().signOut()

        // Log the user back in
        await firebase.auth().signInWithEmailAndPassword(email, password)
      })

  const logout = () => firebase.auth().signOut()

  const switchUser = async token => {
    await logout()
    await firebase.auth().signInWithCustomToken(token)
  }

  const firebaseGetToken = async () =>
    await firebase.auth().currentUser?.getIdToken()

  const firebaseDisplayName = async () =>
    await firebase.auth().currentUser.displayName

  const resetPassword = async email => {
    await firebase.auth().sendPasswordResetEmail(email)
  }

  const updateProfile = () => {}
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  const checkActionCode = async code => {
    const resp = await firebase.auth().checkActionCode(code)
    return resp
  }

  const confirmPasswordReset = async (code, password) => {
    await firebase.auth().confirmPasswordReset(code, password)
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        logout,
        switchUser,
        checkActionCode,
        resetPassword,
        confirmPasswordReset,
        updateProfile,
        firebaseGetToken,
        firebaseDisplayName,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

FirebaseProvider.propTypes = {
  children: PropTypes.node,
}

export default FirebaseContext
