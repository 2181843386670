// routing
import Routes from 'routes'
// import CacheBuster from 'react-cache-buster';
// project imports
import Locales from 'ui-component/Locales'
import NavigationScroll from 'layout/NavigationScroll'
import RTLLayout from 'ui-component/RTLLayout'
import Snackbar from 'ui-component/extended/Snackbar'
import ThemeCustomization from 'themes'
import { Component } from 'react'
import CacheBuster from './CacheBuster'
// Install Hotjar
import Hotjar from '@hotjar/browser'
// Install GA
// import ReactGA from "react-ga4";
import { SnackbarProvider, closeSnackbar } from 'notistack'
// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext'
import { IconButton } from '@mui/material'
import { RxCross1 } from 'react-icons/rx'
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
//
// ==============================|| APP ||============================== //

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload()
          }

          return (
            <ThemeCustomization>
              <RTLLayout>
                <Locales>
                  <NavigationScroll>
                    <AuthProvider>
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        dense
                        maxSnack={3}
                        autoHideDuration={4000}
                        action={snackbarId => (
                          <IconButton
                            sx={{ color: 'white' }}
                            onClick={() => closeSnackbar(snackbarId)}
                          >
                            <RxCross1 size='15px' />
                          </IconButton>
                        )}
                      >
                        <Routes />
                        <Snackbar />
                      </SnackbarProvider>
                    </AuthProvider>
                  </NavigationScroll>
                </Locales>
              </RTLLayout>
            </ThemeCustomization>
          )
        }}
      </CacheBuster>
    )
  }
}

// const siteId = 3672420; this is the hotjar ID for linkedin-mvp-dev
const siteId = 3672417
const hotjarVersion = 6

// Hotjar set up
Hotjar.init(siteId, hotjarVersion)
// GA set up
// const TRACKING_ID = "G-1RVT6P1G57"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

// const browserHistory = createBrowserHistory()
// browserHistory.listen((location, action) => {
//   ReactGA.pageview(location.pathname + location.search)
// })

// ReactGA.send({ hitType: "pageview", page: "/login", title: "Login Page" });
// ReactGA.send({ hitType: "pageview", page: "/setup", title: "Setup Page" });
// ReactGA.send({ hitType: "pageview", page: "/new-messages", title: "New Messages Page" });
// ReactGA.send({ hitType: "pageview", page: "/follow-ups", title: "Follow Ups Page" });
// ReactGA.send({ hitType: "pageview", page: "/analytics", title: "Analytics Page" });
// ReactGA.send({ hitType: "pageview", page: "/support", title: "Support Page" });

// https://stackoverflow.com/questions/52724447/how-to-use-google-analytics-with-react

export default App
