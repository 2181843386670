import React, { useMemo, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import HorizontalBar from "./HorizontalBar";
import useConfig from "hooks/useConfig";
import Cookies from "universal-cookie";
// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      marginTop: 103,
      marginRight: 0,
      padding: 0,
      height: "100%"
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const { container, layout } = useConfig();
  const navigate = useNavigate();
  const cookies = new Cookies();
  
  const header = useMemo(
    () => (
      <Toolbar disableGutters sx={{ p: "0px 10px" }}>
        <Header />
      </Toolbar>
    ),
    [layout, matchDownXS]
  );

  useEffect(() => {
    if (cookies.get("completedSetup") === 'true') {
    } else {
      navigate("/onboarding")
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        // sx={{
        //   transition: drawerOpen ? theme.transitions.create("width") : "none",
        // }}
      >
        {header}
      </AppBar>
      {/* NAVIGATION MENU*/}
      <HorizontalBar />
      {/* main content */}
      <Main theme={theme} layout={layout}>
        <Container
          maxWidth={container ? "lg" : false}
          {...(!container && { sx: { px: { xs: 0 } } })}
        >
          <Outlet />
        </Container>
      </Main>
    </Box>
  );
};

export default MainLayout;
