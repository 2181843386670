// third-party
import { FormattedMessage } from 'react-intl'

// assets
import { IconAdjustmentsAlt } from '@tabler/icons'
// import { numPendingSend } from "store/slices/sendOutreach";
// ==============================|| setup MENU ITEMS ||============================== //

// const num = numPendingSend()
const Setup = {
  id: 'Setup',
  title: <FormattedMessage id='Manage Settings' />,
  icon: IconAdjustmentsAlt,
  type: 'item',
  url: '/settings',
}

export default Setup
