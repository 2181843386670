// third-party
import { combineReducers } from 'redux'
// project imports
import sendOutreachReducer from './slices/sendOutreach'
import menuReducer from './slices/menu'
import snackbarReducer from './slices/snackbar'
import userReducer from './slices/user'
import analyticsReducer from './slices/analytics'
import modelSettingsReducer from './slices/modelSettings'
import campaignsReducer from './slices/campaigns'
import trackConvoReducer from './slices/conversations'
import calendarReducer from './slices/calendar'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  menu: menuReducer,
  snackbar: snackbarReducer,
  analytics: analyticsReducer,
  modelSettings: modelSettingsReducer,
  send_outreach: sendOutreachReducer,
  campaigns: campaignsReducer,
  user: userReducer,
  conversations: trackConvoReducer,
  calendar: calendarReducer,
})

export default reducer
