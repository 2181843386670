// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconPlaylistAdd } from "@tabler/icons";

// ==============================|| setup MENU ITEMS ||============================== //

const GenerateMessages = {
  id: "GenerateMessages",
  title: <FormattedMessage id="Generate Messages" />,
  icon: IconPlaylistAdd,
  type: "item",
  url: "/generate-messages",
};

export default GenerateMessages;
